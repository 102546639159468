import React, { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import Collapse from "../../components/atoms/colapso";
import Footer from "../../components/organisms/footer";
import NavBar from "../../components/organisms/navbar";
import { FaMapMarkerAlt } from "react-icons/fa";
//import useAuthenticate from "../../hooks/useAuthenticate";
import {
  Container,
  Content,
  H3,
  H5,
  Location,
  P,
  QRCodeImg,
  Span,
  Title,
  TournamentActions,
  TournamentBanner,
  TournamentContainer,
  TournamentContainerBanner,
  TournamentContent,
  TournamentDescription,
  TournamentEnrollmentLink,
  TournamentEnrollmentLinkEndpage,
  TournamentLink,
  EditalContainer,
  EditalContent,
  EditalTitle,
} from "./styles";

import { SponsorContainer, SponsorList } from "src/components/atoms/sponsor-area/styles";
import SponsorButton from "src/components/atoms/sponsor-area";
import useAuthenticate from "../../hooks/useAuthenticate";

//Patrocinio
import torneio from "../../assets/images/sponsors/torneio-facil.png";
import logoCampeoesKaizen from "../../assets/images/sponsors/logoCampeoesKaizen.png";
import api from "../../services/api";


//Apoio
import thomaziHotel from "../../assets/images/sponsors/thomasi-hotel.jpg";
import eficazEducacional from "../../assets/images/sponsors/eficazEducacional.jpeg";
import oticaHerval from "../../assets/images/sponsors/oticaHerval.jpeg";
import bravoSix from "../../assets/images/sponsors/bravoSix.jpeg";
import distribuidoraA from "../../assets/images/sponsors/distribuidoraA.jpeg";
import univida from "../../assets/images/sponsors/univida.jpeg";
import radamesEventos from "../../assets/images/sponsors/radamesEventos.jpeg";
import tabelaPeso from "../../assets/images/tournaments/vii-copa-campeoes-de-jiu-jitsu-2025/tabela-vii-copa-campeoes-de-jiu-jitsu-2025.jpg";
import qrCodecopaAmizadeDeJiuJitsu2024 from "../../assets/images/tournaments/copa-amizade-de-jiu-jitsu-2024/qr-coce--copa-amizade-de-jiu-jitsu-2024.jpg";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const folderTorneio = require("../../assets/images/tournaments/vii-copa-campeoes-de-jiu-jitsu-2025/vii-copa-campeoes-de-jiu-jitsu-2025.jpeg");
const rotaInscriçãoGi = "/enrollment/vii-copa-campeoes-de-jiu-jitsu-maringa-pr-gi";
const rotaInscriçãoNoGi = "/enrollment/xviii-copa-parana-de-jiu-jitsu-cascavel-pr-nogi";
const rotaChecagemGi = "/check/vii-copa-campeoes-de-jiu-jitsu-maringa-pr-gi/search";
const rotaChecagemNoGi = "/check/xviii-copa-parana-de-jiu-jitsu-cascavel-pr-nogi/search";
const rotaChavesGI = "http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.5B5B1C9A&u=teste"
const rotaChavesNoGI = "/enrollment/copa-fronteira-de-jiu-jitsu-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr";

const dataEvento = "09 de março de 2025";
const horaEvento = "9:00h da manhã";
const tituloEvento = "VII Copa Campeões de Jiu Jitsu Gi";
const localEvento = "Ginásio de Esportes Parque do Japão";
const endereçoEvento = "R. Tulípa - Parque Industrial, Maringá - PR, 87065-320";
const dataDeEncerramentoIncricao = "07/MAR/2025";
const horaDeEncerramentoIncricao = "23h:59min";

const dataDeEncerramentoCorrecao = "07/MAR/2025";
const horaDeEncerramentoCorrecao = "23h:59min";

const dataDeEncerramentoInscricaoLote1 = "01/MAR/2025";
const dataDeEncerramentoInscricaoLote2 = "1/MAR/2025";
const dataDeEncerramentoInscricaoLote3 = "13/MAR/2025";

const valorinscricaoCategoriaGi = "R$130,00 (Cento e trinta reais)";
const valorinscricaoCategoriaNoGi = "";
const valorinscricaoCategoriaComboGiNoGi = "";
const valorinscricaoCategoriaLote3 = "R$";

const valorinscricaoAbsolutoLote1 = "";
const valorinscricaoAbsolutoLote2 = "";

const dataDeDivulgacaoChaves = "08/MAR/2025";
const horaDeDivulgacaoChaves = "23h:59min";

const instituicaoBancaria = "C6 Bank";
const tipoDeChavePix = "CPF";
const ChavePix = "11437838910";
const BeneficiarioDaChavePix = "Melissa Chaves";

const dataDeDivulgacaoCronograma = "15/MAR/2025";
const horaDeDivulgacaoCronograma = "23h:59min";

const contatoWhatsApp = "+55 (11) 94456-3515";
const contatoWhatsAppTorneioFacil = "+55 (11) 94456-3515";

const slugGI = "vii-copa-campeoes-de-jiu-jitsu-maringa-pr-gi";
const slugNOGI = "";

const sponsors = [
  { img: logoCampeoesKaizen },

];

const support = [

  { img: thomaziHotel },
  { img: eficazEducacional },
  { img: oticaHerval },
  { img: distribuidoraA },
  { img: bravoSix },
  { img: univida },
  { img: radamesEventos },
  { img: torneio, url: "#" },
];

const ViiCopaCampeoesDeJiuJitsu: React.FC = () => {


  useEffect(() => {
    // Adiciona cache buster se não estiver presente
    if (!window.location.search.includes("cacheBuster")) {
      const cacheBuster = `cacheBuster=${new Date().getTime()}`;
      const newUrl = `${window.location.pathname}?${cacheBuster}`;
      window.location.replace(newUrl); // Redireciona para a nova URL
    }
  }, []);



  const { authenticated } = useAuthenticate();
  const navigate = useNavigate();
  const [tournamentGI, setTournamentGI] = useState<any>();
  const [tournamentNOGI, setTournamentNOGI] = useState<any>();

  useEffect(() => {
    api.get(`/tournaments/${slugGI}`).then(({ data }) => {
      setTournamentGI(data);
    });

    api.get(`/tournaments/${slugNOGI}`).then(({ data }) => {
      setTournamentNOGI(data);
    });
  }, []);

  const handleToSubscriptionPage = (url: string) => {
    if (tournamentGI!.canSubscribe || tournamentNOGI!.canSubscribe) {
      navigate(url);
      return;
    }

    const msg = "Período de inscrição encerrado.";

    Swal.fire({
      title: 'Atenção!',
      text: msg,
      icon: 'error',
    });

    toast.error(msg);
  }

  return (
    <>
      <div translate="no">
        <NavBar />
        <Container>
          <TournamentContainer image={folderTorneio}>
            <TournamentContainerBanner>
              <TournamentBanner image={folderTorneio} />
            </TournamentContainerBanner>
          </TournamentContainer>
          <TournamentContent>
            <Content>
              <Title>{tituloEvento}</Title>
              <Location>
                <Span>Data: {dataEvento}</Span>
                <Span>Início: {horaEvento}</Span>
                <Span>Local: {localEvento}</Span>
                <Span>Endereço: {endereçoEvento}</Span>
                <Span>
                  <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                    <FaMapMarkerAlt style={{ marginRight: "8px", color: "red" }} />
                    <a
                      target={"blank"}
                      style={{ color: "#000", textDecoration: "none", fontWeight: "bold" }}
                      href="https://maps.app.goo.gl/jB6TRS4cRggihDkC6"
                    >
                      Ver no Google Maps
                    </a>
                  </div>
                </Span>
              </Location>
              <P></P>
              <br />
            </Content>
          </TournamentContent>
          <TournamentDescription>
            <TournamentActions>
              <Content>
                <h1 style={{ textAlign: "center" }}>REALIZAÇÃO:</h1>
                <SponsorContainer>
                  <SponsorList>
                    {sponsors &&
                      sponsors.map((buttons: any, index: number) => (
                        <SponsorButton
                          key={index} // Adicionei uma chave única para cada botão
                          img={buttons.img}
                          url={buttons.url}
                          hasError={false}
                          error="Este campo é obrigatório"
                          target="_blank" // Adicione este atributo
                        />
                      ))}
                  </SponsorList>
                </SponsorContainer>
                <h1 style={{ textAlign: "center" }}>APOIO:</h1>
                <SponsorContainer>
                  <SponsorList>
                    {support &&
                      support.map((buttons: any) => (
                        <SponsorButton
                          img={buttons.img}
                          url={buttons.url}
                          hasError={false}
                          error="Este campo é obrigatório"
                        />
                      ))}
                  </SponsorList>
                </SponsorContainer>
              </Content>

              <Content
                style={{
                  marginTop: "32px",
                }}
              >
                <EditalContainer>
                  <EditalTitle>EDITAL {tituloEvento.toLocaleUpperCase()}</EditalTitle>
                  <EditalContent>
                    <Span>
                      A {tituloEvento} é um evento tradicional que
                      fomenta e incentiva a prática do jiu jitsu na cidade de Maringá-PR  . As lutas
                      serão GI (com kimono), o evento disponibilizará
                      categorias para todas as idades e faixas, conforme as tabelas de peso
                      deste edital.  Os competidores terão a oportunidade de testar suas
                      habilidades em um ambiente controlado e com acompanhamento de
                      profissionais qualificados. Além das categorias principais, o evento contará
                      com o Festival Kids, destinado a crianças de até 06 anos. Nesta categoria especial,
                      todos os pequenos atletas serão premiados com medalhas, reforçando o espírito esportivo e
                      incentivando a participação desde cedo.
                    </Span>
                    <br />
                    <br />
                    <br />
                  </EditalContent>

                  <Collapse title="PROTOCOLO DE SEGURANÇA CONTRA COVID-19">
                    <P>
                      Devido a dinâmica da situação atual, estamos avaliando diariamente as
                      recomendações dos Órgãos de Saúde e este protocolo pode ser atualizado
                      a qualquer momento caso novas determinações sejam publicadas.
                    </P>
                    <P>
                      Caso o evento seja cancelado por força maior, todas as inscrições
                      serão automaticamente reembolsadas.
                    </P>
                    <P>
                      A organização não é responsável pelos demais custos dos participantes,
                      tais como transporte e hospedagem.
                    </P>
                    <P>
                      Diretrizes do ginásio: Para a segurança de todos, por favor verifique
                      sua temperatura antes de se dirigir ao evento.{" "}
                    </P>
                    <P>Caso haja qualquer sintoma, procure um médico.</P>{" "}
                    <P>
                      {" "}
                      Caso você se sinta indisposto ou esteve em contato com um paciente
                      diagnosticado com Covid-19, por favor fique em casa e procure seu
                      médico.
                    </P>
                    {/* <P>
                  Todo nosso material será desinfetado antes e durante o evento.
                </P> */}
                  </Collapse>
                  <Collapse title="PREMIAÇÕES MEDALHAS🥇 +DINHEIRO💰">
                    {/*    <H3>Academias:</H3>
                  <P>1° lugar: Troféu 🏆</P>
                  <P>2° lugar: Troféu 🏆</P>
                  <P>3° lugar: Troféu 🏆</P> */}


                    <br />
                    <H3>Absoluto Masculino GI:</H3>

                    <H5>Adulto/Master Branca - Juntos</H5>
                    <P>1° lugar: R$200,00 + Medalha🥇</P>
                    <P>2° lugar: medalha🥈</P>
                    <P>3° lugar: medalha🥉</P>

                    <H5>Adulto/Master Azul - Juntos</H5>
                    <P>1° lugar: R$300,00 + Medalha🥇</P>
                    <P>2° lugar: medalha🥈</P>
                    <P>3° lugar: medalha🥉</P>

                    <H5>Adulto/Master Roxa - Juntos</H5>
                    <P>1° lugar: R$400,00 + Medalha🥇</P>
                    <P>2° lugar: medalha🥈</P>
                    <P>3° lugar: medalha🥉</P>

                    <H5>Adulto/Master Marrom - Juntos</H5>
                    <P>1° lugar: R$600,00 + Medalha🥇</P>
                    <P>2° lugar: medalha🥈</P>
                    <P>3° lugar: medalha🥉</P>

                    <H5>Adulto Preta </H5>
                    <P>1° lugar: R$800,00 + Medalha🥇</P>
                    <P>2° lugar: medalha🥈</P>
                    <P>3° lugar: medalha🥉</P>

                    <H5>Master 1 e 2 Preta - Juntos</H5>
                    <P>1° lugar: R$700,00 + Medalha🥇</P>
                    <P>2° lugar: medalha🥈</P>
                    <P>3° lugar: medalha🥉</P>
                    <br />

                    <H3>Absoluto Feminino GI:</H3>

                    <H5>Adulto/Master Branca - Juntos</H5>
                    <P>1° lugar: R$200,00 + Medalha🥇</P>
                    <P>2° lugar: medalha🥈</P>
                    <P>3° lugar: medalha🥉</P>

                    <H5>Adulto/Master Azul e Roxa - Juntos</H5>
                    <P>1° lugar: R$300,00 + Medalha🥇</P>
                    <P>2° lugar: medalha🥈</P>
                    <P>3° lugar: medalha🥉</P>


                    <H5>Adulto/Master Marrom e Preta - Juntos</H5>
                    <P>1° lugar: R$500,00 + Medalha🥇</P>
                    <P>2° lugar: medalha🥈</P>
                    <P>3° lugar: medalha🥉</P>

                    <br />
                    <H3>Demais Categorias GI</H3>
                    <P>1° lugar: medalha🥇</P>
                    <P>2° lugar: medalha🥈</P>
                    <P>3° lugar: medalha🥉</P>
                    <br />
                    <p>
                      Estas premiações só irão valer para os Absolutos que tiverem 06
                      (SEIS) ou mais atletas inscritos.
                    </p>

                    <P>
                      Para a realização de lutas na categoria absoluto, é exigido um mínimo
                      de 6 (SEIS) atletas inscritos , caso contrário não haverá a premiação em dinheiro , somente medalha.
                    </P>
                    <p>
                      Mais informações sobre as inscrições para o absoluto está disponível
                      na seção INSCRIÇÕES, parágrafo Inscrição de Absoluto deste edital.
                    </p>
                    <br />
                  </Collapse>

                  <Collapse title="INSCRIÇÕES">
                    <Span>
                      As inscrições para o evento serão realizadas exclusivamente online,
                      por meio do site www.torneiofacil.com.br
                    </Span>
                    <P>
                      Durante o processo de inscrição, o atleta ou seu responsável legal
                      deverá selecionar corretamente a categoria referente idade que o
                      atleta completará no ano de 2025, bem como sua faixa e peso.
                    </P>
                    <P>
                      A idade de competição do atleta é a idade máxima que ele terá em 2025,
                      conforme regra da CBJJ.
                    </P>
                    <br />

                    <H3>Prazo de Inscrição</H3>
                    <P>
                      O prazo final para inscrições e pagamento será até o dia{" "}
                      {dataDeEncerramentoIncricao}, às {horaDeEncerramentoIncricao}.
                    </P>
                    <br />
                    <P>As inscrições que não forem pagas serão canceladas.</P>
                    <br></br>

                    <H3>Correção</H3>

                    <P>
                      A correção da inscrição poderá ser realizada somente após a
                      confirmação do pagamento.
                    </P>
                    <P>
                      Em caso de inscrição equivocada, o atleta ou professor responsável
                      poderá corrigir as informações no site www.torneiofacil.com.br,
                      acessando a opção Meu Perfil em seguida Minhas Inscrições.
                    </P>
                    <P>
                      A correção da inscrição ficará disponível até o encerramento das
                      inscrições {dataDeEncerramentoCorrecao} às{" "}
                      {horaDeEncerramentoCorrecao}.
                    </P>
                    <P>
                      Após prazo para correção das inscrições, não serão aceitas
                      modificações nas inscrições.
                    </P>
                    <P>
                      Os atletas que se inscreverem incorretamente estarão sujeitos a
                      desclassificação <b>sem direito a reembolso.</b>
                    </P>

                    <br />

                    <H3>Prazo de Correção</H3>
                    <P>
                      A correção da inscrição fica disponível após a confirmação do
                      pagamento da mesma.
                    </P>


                    <H3>Chaves</H3>

                    <P>
                      O sorteio e divulgação das chaves de lutas será no dia{" "}
                      {dataDeDivulgacaoChaves} até às {horaDeDivulgacaoChaves}.
                    </P>

                    <br />
                    <H3>Inscrição de Absoluto</H3>
                    <P>
                      Somente atletas inscritos na categoria poderão se inscrever e
                      participar do absoluto.
                    </P>
                    <P>
                      Os atletas que estiverem inscritos apenas no absoluto sem estar inscrito na categoria será desclassificado
                      sem direito a reembolso ou será remanejado para a categoria Pesadíssimo, a decisão ficará a critério da
                      organozação do evento.
                    </P>
                    <P>
                      Os atletas só poderão se inscrever no absoluto referente a categoria
                      da sua inscrição no torneio. Exceção apenas para os master que poderá
                      se inscrever como absoluto adulto.
                    </P>

                  </Collapse>
                  <Collapse title="PESSOAS COM DEFICIÊNCIA (PCD)">
                    <H3>
                      As pessoas com deficiência (PCD) devem informar, no momento da
                      inscrição, sua condição pelo WhatsApp. Caso contrario o atleta será
                      desclassificado sem direito a reembolso.
                    </H3>
                    <br />
                  </Collapse>
                  <Collapse title="VALOR DA INSCRIÇÃO">
                    <Span>As inscrições serão divididas em dois lotes</Span>
                    <br />
                    {/*  <H3>Inscrições até o dia {dataDeEncerramentoInscricaoLote1}</H3>
                  <br />
                  <P> CATEGORIA GI (COM KIMONO) R$ {valorinscricaoCategoriaGi}</P>
                  <br />
                  <P> CATEGORIA NOGI (SEM KIMONO) R$ {valorinscricaoCategoriaNoGi}</P>
                  <br />
                  <P> COMBO CATEGORIA GI + CATEGORIA NOGI R$ {valorinscricaoCategoriaComboGiNoGi}</P> */}
                    {/* <P>Inscrição absoluto R$ {valorinscricaoAbsolutoLote1}</P> */}
                    <span><b>LOTE 1 - R$ 100,00 (GI) </b></span>
                    <br />
                    <span>R$ 50,00 CATEGORIA ABSOLUTO</span>
                    <br />
                    <span>Pagamento até 01/MAR/2025</span>
                    <br />
                    <br />
                    <span><b>LOTE 2 - R$ 130,00 (GI) </b></span>
                    <br />
                    <span>R$ 50,00 CATEGORIA ABSOLUTO</span>
                    <br />
                    <span>Pagamento até 07/MAR/2025</span>
                    <br />

                  </Collapse>
                  <Collapse title="PAGAMENTO">
                    <H3>
                      A confirmação de pagamento deve ser feita pelo envio do comprovante via WhatsApp para o contato abaixo.
                    </H3>
                    <H3>
                      Caso o atleta não envie o comprovante de pagamento e o número de sua inscrição até o fim do período de
                      inscrições {/* ({dataDeEncerramentoInscricaoLote1}) */}, ele será desclassificado sem direito a reembolso.
                    </H3>
                    <P>Banco: {instituicaoBancaria}</P>
                    <P>Titular: {BeneficiarioDaChavePix}</P>
                    <P>Tipo de chave PIX: {tipoDeChavePix}</P>
                    <P>Chave PIX: {ChavePix}</P>
                    {/* <QRCodeImg src={qrCodecopaAmizadeDeJiuJitsu2024} alt="qrCode" width={300} />
                  <P>
                    <a
                      target={"blank"}
                      style={{ color: "white" }}
                      href="https://whatsa.me/5592981291027/?t=Ol%C3%A1+Thiago_Dellarmelina%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+na+%2ACOPA+ORLEY+LOBATO+DE+JIU+JITSU+2024%2A"
                    >
                      <strong>WhatsApp: +55 (92) 98129-1027 </strong>
                      <FaWhatsapp />
                    </a>
                  </P> */}
                    <p />
                    {/* <QRCodeImg src={qrCodeCopaParana} alt="qrCode" width={300} /> */}
                  </Collapse>

                  <Collapse title="CATEGORIAS COM APENAS UM COMPETIDOR (SEM LUTA)">
                    <Span>
                      Atletas que estiverem sozinhos na categoria terão 02 opções:
                    </Span>
                    <P>
                      Permanecer sozinho na categoria, ser campeão e medalhar sem lutar;
                    </P>
                    <P>Mudar de categoria até a data de encerramento das inscrições;</P>
                    {/*   <P>NÃO HAVERÁ LUTAS CASADAS NESSE EVENTO</P> */}
                  </Collapse>

                  <Collapse title="REEMBOLSO DAS INSCRIÇÕES">
                    <H3>
                      Em caso de Lesão ou doença , o atleta poderá solicitar o reembolso da
                      inscrição, sendo obrigatório a comprovação e apresentação de atestado
                      médico.
                    </H3>
                    <H3>
                      Após o fim do período de inscrição não será possível o reembolso das
                      inscrições pagas.
                    </H3>
                  </Collapse>

                  <Collapse title="REGRAS">
                    <Span>
                      Todas as regras para o evento estão devidamente estipuladas e
                      detalhadas neste edital.
                    </Span>
                    <Span>
                      Em casos de omissão de regras no presente edital, as normas a serem
                      observadas serão aquelas estabelecidas pela Confederação Brasileira de
                      Jiu-Jitsu (CBJJ), em conformidade com as diretrizes da CBJJ/IBJJF.
                    </Span>
                    <Span>
                      A tabela de peso por categoria, que estabelece os limites de peso para
                      cada categoria, encontra-se disponível no site oficial do evento,
                      acessível por meio do botão denominado "Tabela de Peso".
                    </Span>
                    <Span>
                      As lutas serão com GI (com kimonos de cores Oficiais pela cbjj branco,
                      azul e preto ) os kimonos devem estar limpos sem rasgos e sem odor .
                    </Span>
                    {/*    <Span>
                    Haverá premiação em dinheiro para para a academia campeã.
                  </Span> */}
                    <Span>
                      Haverá premiação em dinheiro para o primeiro lugar , somente na
                      categoria absoluto.
                    </Span>
                    <Span>
                      Atletas até 06 anos será no formato Festival, onde todos ganham medalha de 1° lugar, com chave de 04 atletas
                    </Span>
                    <Span>
                      Atletas de 07, 08 e 09 anos haverá premiação de 1°, 2°, 3° e 4° lugares, com chave de 04 atletas
                    </Span>
                    <Span>
                      Atletas de 10 anos acima, premiação somente de 1°, 2° e 3° lugares, sem limite de atletas nas chaves
                    </Span>

                    <Span>
                      O atleta que perdeu a semifinal para o Campeão, ficará em 3° lugar
                    </Span>
                    <Span>
                      Para categoria com 3 lutadores, os atletas farão a melhor de três para
                      definição do campeão, segundo e terceiro lugar conforme regra da CBJJ
                    </Span>
                    <Span>
                      A pesagem será realizada no dia do evento. O competidor deverá
                      apresentar documento oficial com foto no momento da pesagem.
                    </Span>
                    <Span>
                      A pesagem dos atletas serão realizadas antes do inicio da categoria.
                    </Span>
                    <Span>
                      Atletas que ultrapassarem o limite de peso da categoria que estiverem
                      inscritos serão automaticamente desclassificados e não poderão lutar o
                      Absoluto.
                    </Span>
                    <Span>Será realizada apenas uma pesagem oficial por atleta.</Span>
                    {/* <Span>
                    Teremos uma balança para pesagem livre (não oficial) para os atletas.
                  </Span> */}
                    <Span>Teremos uma balança para pesagem oficial dos atletas.</Span>
                    <Span>
                      Atletas que lutaram em outros eventos com faixa superior não poderão
                      lutar com a faixa abaixo. Se descoberto, o competidor será eliminado
                      do torneio sem direito a reembolso.
                    </Span>
                    <br />
                    {/* <H3>Pontuação por equipe</H3>
                <H5>Categoria</H5>
                <P>1° lugar: 9 pontos</P>
                <P>2° lugar: 3 pontos</P>
                <P>3° lugar: 1 ponto</P>
                <H5>Absoluto</H5>

                <P>1° lugar: 13.5 pontos</P>
                <P>2° lugar: 4.5 pontos</P>
                <P>3° lugar: 1.5 ponto</P>
                <br /> */}

                    {/* <P>
                    A contagem dos pontos será realizada de forma geral contabilizando os
                    pontos de todas as faixas.
                  </P>
                  <P>Campeão direto não contam pontos, chaves com apenas um inscrito.</P>
                  <P>
                    Campeão por WO, devido a desistencia do oponente, contará pontos desde
                    que na chave tenha mais de um inscrito.
                  </P>
                  <P>
                    Categorias com apenas dois atletas da mesma academia não contam pontos
                    para a disputa por academias.
                  </P>
                  <P>
                    O que não estiver neste edital, será acatado o que estiver no livro de
                    regras da CBJJ.
                  </P>
                  <br />
                  <P>
                    O que não estiver neste edital, será acatado o que estiver no livro de
                    regras da CBJJ.
                  </P> */}
                    <br />
                    <P>
                      <a
                        target={"blank"}
                        href="https://cbjj.com.br/books-videos"
                        style={{ color: "white" }}
                      >
                        <strong>LIVRO DE REGRAS DA CBJJ/IBJJF</strong>
                      </a>
                    </P>
                  </Collapse>
                  <Collapse
                    title={
                      "CRONOGRAMA "
                    }
                  >
                    (EM BREVE)
                    <Container>
                      
                        <span>
                          CRONOGRAMA 7ª COPA CAMPEÕES DE JIU-JITSU 2025
                        </span>
                        <h5>08:30 - Categorias Pré-Mirim, Mirim A e Mirim B Masculino/Feminino na área de aquecimento</h5>
                        <h5>08:40 - Abertura do evento</h5>
                        <h5>09:00 - Início do evento</h5>
                        <h5>10:30 - Infantil A - Todas as Faixas Masculino/Feminino</h5>
                        <h5>11:00 - Infantil B - Todas as Faixas Masculino/Feminino</h5>
                        <h5>11:30 - Infanto-Juvenil - Todas as Faixas Masculino/Feminino</h5>
                        <h5>12:10 - Juvenil - Todas as Faixas Masculino/Feminino</h5>
                        <h5>12:50 - Faixa Branca Adulto Masculino/Feminino</h5>
                        <h5>13:30 - Faixa Branca Masters Masculino/Feminino</h5>
                        <h5>14:00 - Faixa Azul Adulto e Master Masculino/Feminino</h5>
                        <h5>14:40 - Faixa Roxa Adulto e Master Masculino/Feminino</h5>
                        <h5>15:10 - Faixas Marrom e Preta Adulto e Master Masculino/Feminino</h5>
                        <h5>16:30 - Início dos Absolutos</h5>





                        <br />

                        <Span>
                          O cronograma pode ser adiantado em até 1 hora, e atrasar conforme a
                          necessidade do evento.
                        </Span>
                        <Span>
                          O atleta deverá estar presente no ginásio pelo menos 1 hora antes da
                          sua luta.
                        </Span>
                        <Span>
                          O atleta que não estiver presente no momento da chamada para a
                          pesagem ou luta ser desclassificado por W.O.
                        </Span>
                        <Span>
                          A pesagem será feita antes da 1º luta do atleta, em caso do atleta
                          não bater o peso, este será imediatamente desclassificado, será
                          verificado também as condições do kimono do atleta antes da luta,
                          podendo ocorrer desclassificação.
                        </Span>

                        <Span>
                          Para o que não estiver presente neste edital, seguiremos normas e regras da CBJJ.
                        </Span>

                        <br />
                        <b>
                          <Span>Obs: </Span>
                        </b>
                        <H3> Desejamos a todos um excelente campeonato!!!</H3>
                        <H3>Ossssssssss</H3>
                        <br />
                    </Container>
                  </Collapse>
                  <Collapse title="ENTRADA">
                    <P>Entrada liberada ao público.</P>
                    <br />
                    {/* <P>
                  Será cobrado 1kg de alimento não perecível na entrada para atletas e
                  público.
                </P> */}
                    {/* <br />
                <P>Crianças até 5 anos ficam isentas de qualquer tipo de pagamento.</P>
                <br /> */}
                  </Collapse>
                  <Collapse title="CONTATO">
                    {/* y */}
                    <P>
                      Dúvidas sobre cadastro, inscrição, pagamento, correção, checagem ou
                      sugestão de melhorias entre em contato com a equipe do Torneio Fácil
                      por telefone ou WhatsApp.
                    </P>
                    <P>Melhor horário para atendimento das 18:00h as 23:59h.</P>
                    <a
                      target={"blank"}
                      href="https://whatsa.me/5511944563515/?t=Olá%20Equipe%20Torneio%20Fácil!%0A%0APreciso%20de%20ajuda%20com%20minha%20inscri%C3%A7%C3%A3o%20na%20*VII%20COPA%20CAMPEÕES%20DE%20JIU%20JITSU%202025*"
                    >
                      <strong>Telefone/WhatsApp: {contatoWhatsAppTorneioFacil} </strong>
                      <FaWhatsapp />
                    </a>

                    <br />
                  </Collapse>
                </EditalContainer>
              </Content>
              <br />
              <Content>
                {authenticated ? (
                  <>
                    {/* <TournamentEnrollmentLinkEndpage to={rotaInscriçãoGi}>
                    Inscrição GI
                  </TournamentEnrollmentLinkEndpage>

                  <TournamentEnrollmentLinkEndpage to={rotaInscriçãoNoGi}>
                    Inscrição NOGI
                  </TournamentEnrollmentLinkEndpage> */}

                    <TournamentEnrollmentLinkEndpage to={""} onClick={() => handleToSubscriptionPage(rotaInscriçãoGi)}>
                      Inscrição
                    </TournamentEnrollmentLinkEndpage>

                    <TournamentEnrollmentLinkEndpage to="/profile/subscription">
                      Correção da Inscrição
                    </TournamentEnrollmentLinkEndpage>
                  </>
                ) : (
                  <>
                    <TournamentEnrollmentLinkEndpage to="" onClick={() => handleToSubscriptionPage("/login")}>
                      Inscrição
                    </TournamentEnrollmentLinkEndpage>

                    <TournamentEnrollmentLinkEndpage to="/login">
                      Correção da Inscrição
                    </TournamentEnrollmentLinkEndpage>
                  </>
                )}

                <TournamentLink
                  // target="_blank"
                  href={rotaChecagemGi}
                >
                  Checagem
                </TournamentLink>

                {/*  <TournamentLink
                // target="_blank"
                href={rotaChecagemNoGi}
              >
                Checagem NOGI
              </TournamentLink> */}

                <TournamentLink
                  // target="_blank"
                  href={rotaChavesGI}
                >
                  Chaves
                </TournamentLink>

                <TournamentLink target="blank" href={tabelaPeso}>
                  Tabela de pesos
                </TournamentLink>
                {/* <TournamentLink href="#">Resultados (EM BREVE)</TournamentLink> */}
              </Content>
            </TournamentActions>
          </TournamentDescription>
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default ViiCopaCampeoesDeJiuJitsu;
