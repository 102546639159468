import React, { useEffect } from "react";
import { Field, InputContainer, Label, ValidateHelp } from "./styles";

type InputMaskCustomProps = {
  mask: string;
  hasError?: boolean;
  error?: string;
  name: string;
  register?: any;
  required?: boolean;
  placeholder?: string;
  label?: string;
  onChange?: (...event: any[]) => void;
  onBlur?: () => void;
  value?: any;
  ref?: React.Ref<any>;
};

const InputMaskCustom: React.FC<InputMaskCustomProps> = ({
  mask,
  name,
  hasError,
  error,
  register,
  required,
  placeholder,
  label,
  onChange,
  onBlur,
  value,
  ref,
}) => {
  const [messageError, setMessageError] = React.useState("");

  useEffect(() => {
    if (hasError && error) {
      setMessageError(error);
    } else {
      setMessageError("");
    }
  }, [hasError, error]);

  const fieldProps = register
    ? { ...register(name, { required }) }
    : { name, onChange, onBlur, value, ref };

  return (
    <InputContainer>
      <Label>{label}</Label>
      <Field
        {...fieldProps}
        hasError={hasError && error}
        mask={mask}
        placeholder={placeholder}
      />
      <ValidateHelp>{messageError || ""}</ValidateHelp>
    </InputContainer>
  );
};

export default InputMaskCustom;
