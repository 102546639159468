import React from "react";

type props = {
  bgcolor: any;
  progress: any;
  height: any;
}

const ProgressBar: React.FC<props> = ({ bgcolor, progress, height }) => {

  const getColor = () => {
    if (progress >= 70*16) { //estou mutiplicando por 20 porque no progresso eu estou diviindo por 20, ou seja so vai chegar em 100% quando o valor for 2000 mil
      return "#fbd000 50%, #ee2500";
    } else if (progress >= 50*16) { //aqui tambem
      return "#fbd000";
    } else {
      return "#51b919";
    }
  };
  

  const Parentdiv = {
    height: 20,
    width: '100%',
    backgroundColor: 'whitesmoke',   
    border: "1px solid black",
    borderRadius: "10px"
  }

  const Childdiv:any = {
    height: '100%',
    width: `${progress/16}%`, //vai demorar 20 vezes mais até chegar em 100% da barra
    background: `linear-gradient(to right, green 0%, ${getColor()})`,
    borderRadius: 40,
    textAlign: 'right'
  }

  const progresstext = {
    padding: 10,
    color: 'black',
    fontWeight: 900
  }


  return (
    <div style={Parentdiv}>
      <div style={Childdiv}>
        {/* <span style={progresstext}>{`${progress.toFixed(2)}%`}</span> */}
        <span style={progresstext}> {`${progress.toFixed(0)}`}</span>
      </div>
    </div>
  )
}

export default ProgressBar;