import { useEffect, useState } from "react";
import api from "../../../services/api";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useAuthenticate from "../../../hooks/useAuthenticate";

import Input from "../../../components/atoms/input";
import Loading from "../../../components/atoms/loading";
import NavBar from "../../../components/organisms/navbar";
import Button from "../../../components/atoms/button";

import { FormContainer } from "../../enrollment/styles";
import { FormGroup, Content, PageTitle } from "./styles";
import InputMaskCustom from "../../../components/atoms/input-mask-custom";
import Select from "../../../components/atoms/select";

export const EditAthletes = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { getToken } = useAuthenticate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const [disabled, setDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  const belts = [
    { title: "Branca".toUpperCase(), option: 1 },
    { title: "Cinza".toUpperCase(), option: 2 },
    { title: "Amarela".toUpperCase(), option: 3 },
    { title: "Laranja".toUpperCase(), option: 4 },
    { title: "Verde".toUpperCase(), option: 5 },
    { title: "Azul".toUpperCase(), option: 6 },
    { title: "Roxa".toUpperCase(), option: 7 },
    { title: "Marrom".toUpperCase(), option: 8 },
    { title: "Preta".toUpperCase(), option: 9 },
  ];

  const onSubmit = (data: any) => {
    setDisabled(true);
    setLoading(true);

    api
      .put(
        `/fighters/${params.id}`,
        {
          ...data,
          name: data.name.trim(),
          mother_name: data.mother_name.trim(),
          genre: data.genre.trim(),
          gym: data.gym.toLowerCase().trim(),
          belt: +data.belt,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then(() => {
        toast.success("Atleta foi atualizado com sucesso!");

        setTimeout(() => {
          navigate("/profile/athletes");
        }, 2000);
      })
      .catch(() => {
        setDisabled(false);
        setLoading(false);
        toast.error("Não foi possível atualizar atleta!");
      });
  };

  const fetchFighter = async () => {
    try {
      const response = await api.get(`/fighters/${params.id}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });

      const data = response.data;
      data.gym = data.gym.toUpperCase();
      reset(data);
      setDataLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFighter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!dataLoaded)
    return <Loading text="Carregando dados do atleta..." />;

  return (
    <>
      <NavBar />
      <Content>
        <PageTitle>Edição de Atleta</PageTitle>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Input
              label="Nome"
              name="name"
              type="text"
              register={register}
              required
              hasError={!!errors.name}
              error="Este campo é obrigatório"
            />
          </FormGroup>
          <FormGroup>
            <Input
              label="Nome da mãe"
              name="mother_name"
              type="text"
              register={register}
              required
              hasError={!!errors.mother_name}
              error="Este campo é obrigatório"
            />
          </FormGroup>
          <FormGroup>
            <Select
              label="Sexo"
              name="genre"
              register={register}
              required
              hasError={!!errors.genre}
              error="Este campo é obrigatório"
              options={[
                { title: "Masculino", option: "Masculino" },
                { title: "Feminino", option: "Feminino" },
              ]}
            />
          </FormGroup>
          <FormGroup>
            <Controller
              name="phone"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <InputMaskCustom
                  {...field}
                  label="Telefone"
                  mask="(99) 99999-9999"
                  required
                  hasError={!!errors.phone}
                  error="Este campo é obrigatório"
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Controller
              name="birth_date"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <InputMaskCustom
                  {...field}
                  label="Data de nascimento"
                  mask="99/99/9999"
                  required
                  hasError={!!errors.birth_date}
                  error="Este campo é obrigatório"
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Input
              label="Academia"
              name="gym"
              type="text"
              register={register}
              required
              hasError={!!errors.gym}
              error="Este campo é obrigatório"
            />
          </FormGroup>
          <FormGroup>
            <Select
              label="Faixa"
              name="belt"
              register={register}
              required
              hasError={!!errors.belt}
              error="Este campo é obrigatório"
              options={belts}
            />
          </FormGroup>
          <Button type="submit" label="Atualizar atleta" disabled={disabled} />
        </FormContainer>
      </Content>
    </>
  );
};
