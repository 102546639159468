import NavBar from "../../../components/organisms/navbar";
import {
  Container,
  Content,
  PaymentContainer,
  Title,
  Title2,
  InstructionTitle,
  InstructionList,
  InstructionItem,
  InstructionStep,
  InstructionItemContent,
  StyledLink,
  HighlightedLink,
} from "../styles";
import { FaWhatsapp } from "react-icons/fa";
import { useParams } from "react-router-dom";
import React from "react";

import Confetti from "react-confetti";
import { useEffect, useState } from "react";

const PaymentEnrollmentCopaInTheGuardDeJiuJitsu = () => {
   const { slug, id } = useParams();
   console.log(slug);
 
   // Estados para controlar os confetes
   const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });
   const [confettiPieces, setConfettiPieces] = useState(300); // Inicia com 300 confetes
 
   useEffect(() => {
     const handleResize = () => {
       setWindowSize({ width: window.innerWidth, height: window.innerHeight });
     };
     window.addEventListener("resize", handleResize);
 
     // 💥 Reduzindo os confetes suavemente para que terminem de cair sem corte
     const interval = setInterval(() => {
       setConfettiPieces((prev) => {
         if (prev <= 0) {
           clearInterval(interval); // 🔴 Para o intervalo quando os confetes chegam a 0
           return 0;
         }
         return prev - 40; // Diminui a quantidade aos poucos
       });
     }, 300); // Redução gradual a cada 300ms
 
     return () => {
       window.removeEventListener("resize", handleResize);
       clearInterval(interval); // Garante que o intervalo não continue rodando ao desmontar o componente
     };
   }, []);
  switch (slug) {
    case "copa-in-the-guard-de-jiu-jitsu-cascavel-pr-gi":
      return (
        <Container>
                    <NavBar />
                    {confettiPieces > 0 && (
                      <Confetti width={windowSize.width} height={windowSize.height} numberOfPieces={confettiPieces} />
                    )}
          <Content>
            <PaymentContainer>
              <Title>Obrigado por fazer a sua inscrição na</Title>
              <Title2>
                {`Copa Condominio Moradas Londrina de Jiu Jitsuu ${id!.split("-").length > 1 ? "": "GI"}  `.toLocaleUpperCase()}
              </Title2>
              <p style={{ color: "red", marginBottom: "0px" }}>
                {
                  id?.split("-").map(i =>  <p><b>Inscrição N° {i}</b></p>)
                }
              </p>
              <InstructionTitle>Instruções:</InstructionTitle>

              <InstructionList>
                {/* <InstructionItem color="#f0f4c3"> */}
                <InstructionItem color="rgb(210, 210, 210)">
                  <InstructionStep>Passo 1</InstructionStep>
                  <InstructionItemContent>
                    Colocar o número da inscrição ({" "}
                    <b style={{ color: "red", marginBottom: "30px" }}>{id}</b> ) na
                    descrição do PIX.
                  </InstructionItemContent>
                </InstructionItem>

                {/* <InstructionItem color="#e3f2fd"> */}
                <InstructionItem color="rgb(210, 210, 210)">
                  <InstructionStep>Passo 2</InstructionStep>
                  <InstructionItemContent>
                    Efetuar o pagamento através do PIX para:
                    <ul style={{ listStyle: "none" }}>
                      <br />
                      <li>Banco NuBank</li>
                      <li>Tipo de Chave PIX Telefone</li>
                      <li>
                        <b>Pix: (43) 98405-2512</b>
                      </li>
                      <li>Sara Bortoli Neves</li>
                    </ul>
                  </InstructionItemContent>
                </InstructionItem>

                <InstructionItem color="rgb(210, 210, 210)">
                  {/* <InstructionItem color="#ffecb3"> */}
                  <InstructionStep>Passo 3</InstructionStep>
                  <InstructionItemContent>
                    Enviar o comprovante de pagamento (com número da inscrição descrição)
                    para:
                    <ul style={{ listStyle: "none" }}>
                      <li>
                        <br />
                        <StyledLink
                          target="_blank"
                          href={`https://whatsa.me/+5543984796143/?t=Olá,%20Eduardo%20Borges!%0A%0AEstou+enviando+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+na+%2ACOPA+CONDOMINIO+MORADAS+LONDRINA+DE+JIU+JITSU%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}*`}
                        >
                          +55 (43) 98479-6143 <FaWhatsapp />
                        </StyledLink>
                      </li>
                    </ul>
                  </InstructionItemContent>
                </InstructionItem>

                <InstructionItem color="rgb(210, 210, 210)">
                  {/* <InstructionItem color="#c8e6c9"> */}
                  <InstructionStep>Passo 4</InstructionStep>
                  <InstructionItemContent>
                    Acompanhe o status do pagamento em:
                    <br />
                    <ul style={{ listStyle: "none" }}>
                      <li>
                        <HighlightedLink href="/profile/subscription">
                          Minhas Inscrições
                        </HighlightedLink>
                      </li>
                    </ul>
                  </InstructionItemContent>
                </InstructionItem>
              </InstructionList>
            </PaymentContainer>
          </Content>
        </Container>
      );
    case "copa-amizade-de-jiu-jitsu-2024-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr":
    case "":
      return (
        <Container>
          <NavBar />
          <Content>
            <PaymentContainer>
              <Title>Obrigado por fazer a sua inscrição no</Title>
              <Title2>
                {`Copa In The Guard de Jiu Jitsu ${id!.split("-").length > 1 ? "": "NOGI"}  `.toLocaleUpperCase()}
              </Title2>
              <p style={{ color: "red", marginBottom: "0px" }}>
                {
                  id?.split("-").map(i => <p><b>Inscrição N° {i}</b></p>)
                }
              </p>
              <InstructionTitle>Instruções:</InstructionTitle>

              <InstructionList>
                {/* <InstructionItem color="#f0f4c3"> */}
                <InstructionItem color="rgb(240, 240, 240)">
                  <InstructionStep>Passo 1</InstructionStep>
                  <InstructionItemContent>
                    Coloca o número da inscrição ({" "}
                    <b style={{ color: "red", marginBottom: "30px" }}>{id}</b> ) na
                    descrição do PIX.
                  </InstructionItemContent>
                </InstructionItem>

                {/* <InstructionItem color="#e3f2fd"> */}
                <InstructionItem color="rgb(230, 230, 230)">
                  <InstructionStep>Passo 2</InstructionStep>
                  <InstructionItemContent>
                    Efetuar o pagamento através do PIX para:
                    <ul style={{ listStyle: "none" }}>
                      <br />
                      <li>Banco Inter</li>
                      <li>Tipo de Chave PIX E-MAIL</li>
                      <li>
                        <b>Pix: financeiro@torneiofacil.com</b>
                      </li>
                      <li>Thiago Souza Dellarmelina</li>
                    </ul>
                  </InstructionItemContent>
                </InstructionItem>

                <InstructionItem color="rgb(220, 220, 220)">
                  {/* <InstructionItem color="#ffecb3"> */}
                  <InstructionStep>Passo 3</InstructionStep>
                  <InstructionItemContent>
                    Enviar o comprovante de pagamento (com número da inscrição descrição)
                    para:
                    <ul style={{ listStyle: "none" }}>
                      <li>
                        <br />
                        <StyledLink
                          target="_blank"
                          href={`https://whatsa.me/5511944563515/?t=Olá,%20Financeiro%20Torneio%20Fácil!%0A%0AEstou+enviando+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+na+%2AXVIII+COPA+PARANÁ+DE+JIU+JITSU%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}*`}
                        >
                          (11) 94456-3515 <FaWhatsapp />
                        </StyledLink>
                      </li>
                    </ul>
                  </InstructionItemContent>
                </InstructionItem>

                <InstructionItem color="rgb(210, 210, 210)">
                  {/* <InstructionItem color="#c8e6c9"> */}
                  <InstructionStep>Passo 4</InstructionStep>
                  <InstructionItemContent>
                    Acompanhe o status do pagamento em:
                    <br />
                    <ul style={{ listStyle: "none" }}>
                      <li>
                        <HighlightedLink href="/profile/subscription">
                          Minhas Inscrições
                        </HighlightedLink>
                      </li>
                    </ul>
                  </InstructionItemContent>
                </InstructionItem>
              </InstructionList>
            </PaymentContainer>
          </Content>
        </Container>
      );
    default:
      return <h1>Pagamento não liberado para esse torneio</h1>;
  }
};
export default PaymentEnrollmentCopaInTheGuardDeJiuJitsu;
