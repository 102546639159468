import styled from "styled-components";

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 18px;
  text-align: left;
  background-color: #f9f9f9;

  th,
  td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: center;
  }

  th {
    background-color: rgb(55, 55, 55);
    color: white;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }
`;

export const StyledButton = styled.button`
  padding: 10px 20px;
  background-color: rgb(55, 55, 55);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px 0;

  &:hover {
    background-color: #45a049;
  }
`;
