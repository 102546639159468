import React from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import Collapse from "../../components/atoms/colapso";
import Footer from "../../components/organisms/footer";
import NavBar from "../../components/organisms/navbar";
//import useAuthenticate from "../../hooks/useAuthenticate";
import {
  Container,
  Content,
  H3,
  H5,
  Location,
  P,
  QRCodeImg,
  Span,
  Title,
  TournamentActions,
  TournamentBanner,
  TournamentContainer,
  TournamentContainerBanner,
  TournamentContent,
  TournamentDescription,
  TournamentEnrollmentLink,
  TournamentEnrollmentLinkEndpage,
  TournamentLink,
} from "./styles";
// import tabelaPeso from "../../assets/images/tournaments/toledo-international-open-brazilian-jiu-jitsu-2023/tabepla-de-peso.jpeg";
import tabelaPeso from "../../assets/images/tournaments/seletiva-manaus-olimpica-2024/tabela-seletiva-manaus-olimpica-2024.jpg"
import qrCodeSeletivaManausOlimpica2024 from "../../assets/images/tournaments/seletiva-manaus-olimpica-2024/qr-seletiva-manaus-olimpica-2024.jpg";
import editalSeletivaManausOlimpica2024 from "../../files/seletiva-manaus-olimpica-2024/edital-seletiva-manaus-olimpica-2025.pdf";
import chavesSeletivaManausOlimpica2024 from "../../files/seletiva-manaus-olimpica-2024/chaves-seletiva-manaus-olimpica-2024.pdf";

//Patrocinio
import torneio from "../../assets/images/sponsors/torneio-facil.png";
import restauranteFeijao from "../../assets/images/sponsors/restaurante-feijao.png";
import manausOlimpica2024 from "../../assets/images/sponsors/manaus-olimpica-2024.png";
import manausSouthAmericanCityOfSport2024 from "../../assets/images/sponsors/manaus-south-american-city-of-sport-2024.png";
import prefeituraDeManaus2024 from "../../assets/images/sponsors/prefeitura-de-manaus-2024.png";
import manausEsporteFundacaoMunicipal from "../../assets/images/sponsors/manaus-esporte-fundacao-municipal.png";
import magistral from "../../assets/images/sponsors/magistral.png";

//Apoio


import { SponsorContainer, SponsorList } from "src/components/atoms/sponsor-area/styles";
import SponsorButton from "src/components/atoms/sponsor-area";
import useAuthenticate from "../../hooks/useAuthenticate";

const sponsors = [
  { img: restauranteFeijao, url: "#" },
  { img: manausOlimpica2024, url: "#" },
  { img: manausSouthAmericanCityOfSport2024, url: "#" },
  { img: prefeituraDeManaus2024, url: "#" },
  { img: manausEsporteFundacaoMunicipal, url: "#" },
  { img: magistral, url: "#" },
  { img: torneio, url: "http://www.torneiofacil.com" },








];

// const support = [
//   //{ img: esporteNaPeriferia },
//   { img: torneio },
// ];

const contatoWhatsAppTorneioFacil = "+55 (11) 94456-3515";

const SeletivaManausOlimpica2024: React.FC = () => {
  const { authenticated } = useAuthenticate();

  return (
    <>
      <NavBar />

      <Container>
        <TournamentContainer
          image={require("../../assets/images/tournaments/seletiva-manaus-olimpica-2024/banner-seletiva-manaus-olimpica-2024.jpg")}
        >
          <TournamentContainerBanner>
            <TournamentBanner
              image={require("../../assets/images/tournaments/seletiva-manaus-olimpica-2024/banner-seletiva-manaus-olimpica-2024.jpg")}
            />
          </TournamentContainerBanner>
        </TournamentContainer>
        <TournamentContent>
          <Content>
            <Title>Seletiva Manaus Olímpica 2025</Title>
            <Location>
              <Span>Data: 08 e 09 de março de 2025</Span>
              <Span>Início: 08:00h da manhã</Span>
              <Span>Local: CEL - Nininberg Guerra</Span>
              <Span> R. Vicente Torres Reis, 657 - São Jorge, Manaus - AM, 69033-030</Span>

              <Span>
                <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                  <FaMapMarkerAlt style={{ marginRight: "8px", color: "red" }} />
                  <a
                    target={"blank"}
                    style={{ color: "#000", textDecoration: "none", fontWeight: "bold" }}
                    href="https://maps.app.goo.gl/nFsJVQHCSTvnzgek7"
                  >
                    Ver no Google Maps
                  </a>
                </div>
              </Span>

              <a
                target={"blank"}
                style={{ color: "black" }}
                href="https://maps.app.goo.gl/PGoDw5pvgaMBmfss7"
              >
                <strong></strong>
              </a>
            </Location>
            <P></P>
            <br />

            <Collapse title="EDITAL SELETIVA MANAUS OLÍMPICA 2025">
              <Span>
                A III Seletiva Manaus Olímpica 2025 é um evento manauara que vem fomentando e
                incentivando a prática do jiu jitsu no Amazonas. As lutas serão exclusivamente GI
                (com kimono), o evento disponibilizará categorias, conforme descrito neste edital.
                Os competidores terão a oportunidade de testar suas habilidades em um ambiente controlado
                e com acompanhamento de profissionais qualificados
                <br />
                <br />

                <a
                  target={"blank"}
                  href={editalSeletivaManausOlimpica2024}
                  // href= "#"
                  style={{ color: "white" }}
                >
                  <strong>DOWNLOAD DO EDITAL</strong>
                </a>{" "}

              </Span>
              <br />
              {/* <Collapse title="PROTOCOLO DE SEGURANÇA CONTRA COVID-19">
                <P>
                  Devido a dinâmica da situação atual, estamos avaliando diariamente as
                  recomendações dos Órgãos de Saúde e este protocolo pode ser atualizado a
                  qualquer momento caso novas determinações sejam publicadas.
                </P>
                <P>
                  Caso o evento seja cancelado por força maior, todas as inscrições serão
                  automaticamente reembolsadas.
                </P>
                <P>
                  A organização não é responsável pelos demais custos dos participantes,
                  tais como transporte e hospedagem.
                </P>
                <P>
                  Diretrizes do ginásio: Para a segurança de todos, por favor verifique
                  sua temperatura antes de se dirigir ao evento.{" "}
                </P>
                <P>Caso haja qualquer sintoma, procure um médico.</P>{" "}
                <P>
                  {" "}
                  Caso você se sinta indisposto ou esteve em contato com um paciente
                  diagnosticado com Covid-19, por favor fique em casa e procure seu
                  médico.
                </P>
                {/* <P>
                  Todo nosso material será desinfetado antes e durante o evento.
                </P> */}
              {/*</Collapse> */}
              <Collapse title="PREMIAÇÕES  ✈️PASSAGENS AEREAS + 💰DINHEIRO  ">



                {/*   <P>
                  Uma (01) Passagem aérea  MAO/SP/MAO e 1.000,00 (Mil Reais) de bônus para o campeão
                  lutar o Campeonato Brasileiro que acontecerá nos dias 20 a 28 de abril nas Categorias
                  Juvenil, Adulto, Master e nos dias 22 E 23 de junho para todas as crianças, no Ginásio
                  Poliesportivo José Corrêa, Barueri-SP (CONFORME A PROGRAMAÇÃO DO CAMPEONATO BRASILEIRO DE
                  JIU-JITSU)
                </P>
 */}
                <P>
                  As categorias estão detalhada no edital.
                </P>
                <br />
                {/* <a
                  target={"blank"}
                  href={editalSeletivaManausOlimpica2024}
                  // href= "#"
                  style={{ color: "white" }}
                >
                  <strong>DOWNLOAD DO EDITAL</strong>
                </a>{" "} */}

                <br />
                {/* <H3>Demais Categorias GI</H3>
                <P>1° lugar: medalha</P>
                <P>2° lugar: medalha</P>
                <P>3° lugar: medalha</P> */}
              </Collapse>
              <Collapse title="INSCRIÇÕES">

                <Span>Vagas limitadas a 1600 inscrições</Span>
                <Span>
                  As inscrições para o evento serão realizadas exclusivamente online, por
                  meio do site www.torneiofacil.com.br.
                </Span>
                <P>Os atletas devem se inscrever em apenas uma categoria.</P>

                <P>
                  Durante o processo de inscrição, o atleta ou seu responsável legal
                  deverá selecionar corretamente a categoria referente idade que o atleta
                  completará no ano de 2025, bem como sua faixa e peso.
                </P>
                <P>
                  A idade de competição do atleta é a idade máxima que ele terá em 2025,
                  conforme regra da CBJJ.
                </P>
                <br />

                <H3>Prazo de Inscrição</H3>
                <P>
                  O prazo final para inscrições e pagamento será até o dia 28/fev/2025, às
                  23h59min.
                </P>
                <br></br>

                <H3>Valor</H3>
                <P>Inscrição GI GRATUITA conforme edital</P>

                <br />
                <H3>Checagem</H3>

                <P>
                  A checagem está disponivel desde o dia 17/fev/2025.
                </P>
                <br />
                <H3>Correção</H3>

                {/* <P>
                  A correção da inscrição ficará disponível até 24 horas após o termino do período de inscrição.
                </P> */}
                <P>
                  Em caso de inscrição equivocada, o atleta ou professor responsável
                  poderá corrigir as informações no site www.torneiofacil.com.br,
                  acessando a opção <b>Meu Perfil</b> em seguida <b>Minhas Inscrições.</b>
                </P>
                <P>
                  A correção da inscrição ficará disponivel até o dia 02/março/2025 as 23h59min.
                </P>
                <P>
                  Após prazo para correção das inscrições, não serão aceitas modificações
                  nas inscrições.
                </P>
                <P>
                  Os atletas que se inscreverem incorretamente estarão sujeitos a
                  desclassificação.
                </P>

                <br />

                <H3>Prazo de Correção</H3>
                <P>
                  A correção da inscrição ficará disponivel até o dia 02/março/2025 as 23h59min.
                </P>

                <H3>Chaves</H3>

                <P>
                  O sorteio e divulgação das chaves de lutas será no dia 07 de março
                  de 2025 até as 23h e 59min.
                </P>

                <br />
              </Collapse>
              <Collapse title="PAGAMENTO">
                <H3>
                  INSCRIÇÃO GRATUITA
                </H3>
                <P>Conforme as regras do edital da Seletiva Manaus Olimpica 2025 :</P>

                <a
                  target={"blank"}
                  style={{ color: "white" }}
                  href="https://whatsa.me/5511944563515/?t=Olá%20Equipe%20Torneio%20Fácil!%0A%0APreciso%20de%20ajuda%20com%20minha%20inscri%C3%A7%C3%A3o%20na%20*III%20SELETIVA%20OLÍMPICA%202025*"

                >
                  <strong>WhatsApp: +55 (11) 94456-3515 </strong>
                  <FaWhatsapp />
                </a>

                <p />
                {/* <QRCodeImg src={qrCodeCopaParana} alt="qrCode" width={300} /> */}
              </Collapse>
              <Collapse title="REGRAS">
                {/*  <Span>
                  Todas as regras para o evento estão devidamente estipuladas e detalhadas neste edital.
                </Span>
                <Span>
                  Atletas vencedores devem estar filiados e inscritos na CBJJ dentro do prazo estabelecido
                  pela FUNDAÇÃO MANAUS ESPORTE para participar do Campeonato Brasileiro de Jiu Jitsu.
                  Aqueles que não estiverem filiados perderão o direito à premiação.
                </Span>
                <Span>
                  Para ser elegível à premiação, o atleta (Masculino e Feminino) deve atender aos requisitos
                  mínimos de pontuação, 20 pontos, estabelecidos pela CBJJ/IBJJF nas
                  categorias Preta Adulto.

                </Span>
                <Span>
                  Todo atleta menor de 16 anos deve ter um responsável presente ou apresentar uma
                  autorização autenticada de viagem para menor. O responsável não terá direito à passagem,
                  somente o atleta será contemplado com a premiação.
                </Span>
                <Span>
                  Somente atletas graduados e filiados a associações e academias da cidade de Manaus podem
                  participar das seletivas.
                </Span>
                <Span>
                  Os atletas devem se inscrever em apenas uma categoria.
                </Span>
                <Span>
                  A FUNDAÇÃO MANAUS ESPORTE não se responsabilizará pelos atletas menores de idade.
                </Span>
                <Span>
                  Após o evento (CAMPEONATO BRASILEIRO DE JIU-JITSU), é obrigatório apresentar
                  um relatório da passagem adquirida, incluindo o bilhete de IDA/VOLTA para os
                  atletas que foram premiados
                </Span>
                <Span>
                  Os atletas que foram contemplados no ano de 2023 com a passagem, devem enviar
                  o RELATÓRIO DE PRESTAÇÃO DE CONTAS DA VIAGEM de 2023 até dia 22/03, caso contrário
                  serão desclassificados.
                </Span>
                <Span>
                  Atletas que lutaram em outros eventos com faixa superior não poderão
                  lutar com a faixa abaixo. Se descoberto, o competidor será desclassificado.
                </Span> */}
                <br />

                <P>
                  As categorias estão detalhada no edital deste evento.
                </P>

                <P>
                  O que não estiver neste edital, será acatado o que estiver no livro de
                  regras da CBJJ.
                </P>

                <P>
                  <a
                    target={"blank"}
                    href="https://cbjj.com.br/books-videos"
                    style={{ color: "white" }}
                  >
                    <strong>LIVRO DE REGRAS DA CBJJ/IBJJF</strong>
                  </a>
                </P>
              </Collapse>
              <Collapse title="CRONOGRAMA">
                {/* (EM BREVE) */}
                <Container>
                  
                  <Span>Data: 08 e 09 de março de 2025</Span>
                  <Span>Início: 08:00h da manhã</Span>
                  <Span>Local: CEL- Nininberg Guerra</Span>

                  <span>
                    III SELETIVA JIU-JITSU MANAUS 2025
                  </span>
                  <h3>CRONOGRAMA DE SÁBADO (08/03/25)</h3>
                  <h5>08:00 - Abertura do evento</h5>
                  <h5>08:30 - Juvenil 1 (16 anos) Masculino</h5>
                  <h5>09:00 - Juvenil 1 (16 anos) Feminino</h5>
                  <h5>09:30 - Juvenil 2 (17 anos) Masculino</h5>
                  <h5>10:00 - Juvenil 2 (17 anos) Feminino</h5>
                  <h5>10:30 - Adulto Faixa Marrom (Masculino)</h5>
                  <h5>11:00 - Adulto Faixa Preta (Masculino e Feminino)</h5>
                  <h5>12:00 - Adulto Faixa Roxa (Masculino e Feminino)</h5>
                  <h5>13:30 - Adulto Faixa Azul (Feminino)</h5>
                  <h5>14:00 - Adulto Faixa Azul Galo/Pluma/Pena (Masculino)</h5>
                  <h5>15:00 - Adulto Faixa Azul Leve/Médio/Meio-Pesado (Masculino)</h5>
                  <h5>16:00 - Adulto Faixa Azul Pesado/Super-Pesado/Pesadíssimo (Masculino)</h5>
                  <p><strong>Obs.:</strong> O atleta deverá comparecer com uma (01) hora de antecedência do horário previsto no cronograma, podendo este adiantar ou atrasar.</p>

                  <h3>CRONOGRAMA DE DOMINGO (09/03/25)</h3>
                  <h5>08:00 - Abertura do evento</h5>
                  <h5>08:30 - 10 anos (Masculino e Feminino)</h5>
                  <h5>09:00 - 11 anos (Masculino e Feminino)</h5>
                  <h5>09:30 - 12 anos (Masculino e Feminino)</h5>
                  <h5>10:00 - 13 anos (Masculino e Feminino)</h5>
                  <h5>10:30 - 14 anos (Masculino e Feminino)</h5>
                  <h5>11:30 - 15 anos (Masculino e Feminino)</h5>
                  <h5>12:30 - Absoluto Master Faixa Azul e Faixa Roxa (Masculino e Feminino)</h5>
                  <h5>13:00 - Absoluto Master Faixa Marrom (Masculino e Feminino)</h5>
                  <h5>14:00 - Absoluto Master Faixa Preta 1 (Masculino)</h5>
                  <h5>14:30 - Absoluto Master Faixa Preta (Feminino)</h5>
                  <h5>15:00 - Absoluto Master Faixa Preta 2 (Masculino)</h5>
                  <h5>15:30 - Absoluto Master Faixa Preta 3 (Masculino)</h5>
                  <h5>16:00 - Absoluto Master Faixa Preta 4/5/6 (Masculino)</h5>
                  <p><strong>Obs.:</strong> O atleta deverá comparecer com uma (01)
                    hora de antecedência do horário previsto no cronograma,
                    podendo este adiantar ou atrasar.</p>



                  {/* <br />
                  <br />
                  <H3>CRONOGRAMA DO DIA 23/março/2025 - SÁBADO</H3>
                  <H5>09:00 horas - Faixa MARROM (masculino e feminino) Adulto.</H5>
                  <H5>10:00 horas - Faixa MARROM (masculino e feminino) Master.</H5>
                  <H5>11:00 horas - Faixa PRETA (masculino e feminino) Adulto.</H5>
                  <H5>12:00 horas - Faixa PRETA (masculino e feminino) Master 1 a 6</H5>
                  <H5>13:00 horas - Faixa ROXA (masculino e feminino) Adulto.</H5>
                  <H5>14:00 horas - Faixa ROXA (masculino e feminino) Master.</H5>
                  <H5>15:00 horas - Faixa AZUL (masculino e feminino) Adulto.</H5>
                  <H5>16:00 horas - Faixa AZUL (masculino e feminino) Master.</H5>
                  <br />
                  <br />
                  <H3>CRONOGRAMA DO DIA 24/março/2025 - DOMINGO </H3>
                  <H5>09:00 horas - Infantil 1,2,3 (10, 11 e 12 anos) masculino.</H5>
                  <H5>10:00 horas - Infantil 1,2,3 (10, 11 e 12 anos) feminino.</H5>
                  <H5>11:00 horas - Infanto-juvenil 1 (13 anos) masculino e feminino.</H5>
                  <H5>12:00 horas - Infanto-juvenil 2 (14 anos) masculino e feminino. </H5>
                  <H5>13:00 horas - Infanto-juvenil 3 (15 anos) masculino e feminino. </H5>
                  <H5>14:00 horas - Juvenil 1 (16 anos) masculino.</H5>
                  <H5>15:00 horas - Juvenil (16/17 anos) Feminino. </H5>
                  <H5>16:00 horas - Juvenil 2 (17 anos) masculino.</H5>

                  <br />
                  <H3>Observação: Os cronogramas são uma previsão de horários, podendo atrasar ou adiantar conforme a necessidade da organização do evento.</H3>

                  <br />
                  <Span>Usaremos 6 áreas de luta</Span>

                  <Span>
                    O atleta deverá estar presente no ginásio pelo menos 1 hora antes da
                    sua luta.
                  </Span>
                  <Span>
                    O cronograma pode ser adiantado em até 1 hora, e atrasar até 3h conforme a
                    necessidade do evento.
                  </Span>
                  <Span>
                    O atleta que não estiver presente no momento da chamada para a pesagem
                    ou luta ser desclassificado por W.O.
                  </Span> */}
                </Container>
              </Collapse>
              <Collapse title="ENTRADA">
                <P>Entrada liberada ao público.</P>
                <br />
              </Collapse>
              <Collapse title="CONTATO">
                {/* y */}
                <P>
                  Dúvidas sobre cadastro, inscrição, pagamento, correção, checagem ou
                  sugestão de melhorias entre em contato com a equipe do Torneio Fácil
                  por telefone ou WhatsApp.
                </P>
                <P>Melhor horário para atendimento das 18:00h as 23:59h.</P>
                <a
                  target={"blank"}
                  href="https://whatsa.me/5511944563515/?t=Olá%20Equipe%20Torneio%20Fácil!%0A%0APreciso%20de%20ajuda%20com%20minha%20inscri%C3%A7%C3%A3o%20na%20*III%20SELETIVA%20OLIMPICA%202025*"
                >
                  <strong>Telefone/WhatsApp: {contatoWhatsAppTorneioFacil} </strong>
                  <FaWhatsapp />
                </a>

                <br />
              </Collapse>
            </Collapse>
          </Content>
        </TournamentContent>
        <TournamentDescription>
          <TournamentActions>
            <Content>
              <h1 style={{ textAlign: "center" }}>PATROCINADORES</h1>
              <SponsorContainer>
                <SponsorList>
                  {sponsors &&
                    sponsors.map((buttons: any) => (
                      <SponsorButton
                        img={buttons.img}
                        url={buttons.url}
                        hasError={false}
                        error="Este campo é obrigatório"
                      />
                    ))}
                </SponsorList>
                {/* </SponsorContainer>
              <h1 style={{ textAlign: "center" }}>APOIO</h1>
              <SponsorContainer>
                <SponsorList>
                  {support &&
                    support.map((buttons: any) => (
                      <SponsorButton
                        img={buttons.img}
                        url={buttons.url}
                        hasError={false}
                        error="Este campo é obrigatório"
                      />
                    ))}
                </SponsorList> */}
              </SponsorContainer>
            </Content>
            <br />
            <Content>
              {authenticated ? (
                <>
                  <TournamentEnrollmentLinkEndpage style={{ backgroundColor: 'red' }} to="/enrollment/seletiva-manaus-olimpica-2024-ginasio-ninimberg-guerra-sao-jorge-manaus-am">
                    Inscrição
                  </TournamentEnrollmentLinkEndpage>

                  <TournamentEnrollmentLinkEndpage style={{ backgroundColor: 'red' }} to="/profile/subscription">
                    Correção
                  </TournamentEnrollmentLinkEndpage>
                </>
              ) : (
                <>
                  <TournamentEnrollmentLinkEndpage to="/login">
                    Inscrição
                  </TournamentEnrollmentLinkEndpage>

                  <TournamentEnrollmentLinkEndpage to="/login">
                    Correção
                  </TournamentEnrollmentLinkEndpage>
                </>
              )}
              {/* <TournamentLink href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.767B171E&u=teste" target="_blank">Chaves</TournamentLink> */}
              {/* // /check/16-COPA-PARANA-DE-JIU-JITSU */}

              <TournamentLink
                // href="#"
                // target="_blank"
                href="/check/seletiva-manaus-olimpica-2024-ginasio-ninimberg-guerra-sao-jorge-manaus-am/search"
              //href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.D7A54E37&u=teste"
              >Checagem</TournamentLink>

              {/* <TournamentLink
                // href="#"
                // target="_blank"
                 href="#"
                //href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.D7A54E37&u=teste"
                target="_blank">Chaves - EM BREVE</TournamentLink> */}

              {/* 
              <TournamentLink
                href="/check/toledo-international-open-brazilian-jiu-jitsu-nogi-2023-ginasio-de-esportes-alcides-pan-toledo-pr/search"
                target="_blank"
              >
                Checagem de atletas NO GI
              </TournamentLink>  */}

       
              <TournamentLink
                href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.DB5B8D07&u=teste"
                target="_blank"
              >
                Resultados
              </TournamentLink>

              <TournamentLink target="blank" href={tabelaPeso}>
                Tabela de pesos
              </TournamentLink>
              {/* <TournamentLink href="#">Resultados (EM BREVE)</TournamentLink> */}
            </Content>
            <br />
            <Content>
              {/* <Collapse title="Reultados Individuais">
                <br />
                <H3>INFANTIL 1 FEM GRADUADO PLUMA, -30,2 KG</H3>
                <P>1º MARÍA FLOR - ROCHA E MORAES BRAZILIAN JIU-JITSU</P>
                <P>2º SOPHYA MELLYSA DOS SANTOS GOMES - TICO BJJ</P>

                <br />
                <H3>INFANTIL 1 FEM GRADUADO LEVE, -36,2 KG</H3>
                <P>1º LAIS NICOLLY SILVA OLIVEIRA - DOJO RAÇA</P>
                <P>2º ISABELLA NATHIELY - PROJ. SUÇUARANA</P>

                <br />
                <H3>INFANTIL 1 FEM GRADUADO MEIO PESADO, -42,3 KG</H3>
                <P>1º FRANCISCA HEVELLYN CUNHA DA SILVA - CLUB PINA/CARLOS HOLANDA</P>

                <br />
                <H3>INFANTIL 1 FEM GRADUADO PESADÍSSIMO</H3>
                <P>1º ANA BEATRIZ NEGREIROS DOURADO - ANIBAL</P>

                <br />
                <H3>INFANTIL 1 MASC GRADUADO LEVE, -36,2 KG</H3>
                <P>1º HENRIQUE GUILHERME RAITZ OLIVEIRA - PROJ. PAI E FILHO</P>
                <P>2º LUCAS PINHEIRO DA SILVA - NABIL</P>

                <br />
                <H3>INFANTIL 1 MASC GRADUADO MEIO PESADO, -42,3 KG</H3>
                <P>1º ARTHUR FELIPE CAVALCANTE DE ABREU - NABIL</P>
                <P>2º LUCAS GABRIEL DA SILVA SOUZA - AMAZONAS TOP TEAM</P>
                <P>3º JOÃO MIGUEL RAMOS DA CONCEIÇÃO - KIMURA</P>

                <br />
                <H3>INFANTIL 1 MASC GRADUADO PESADÍSSIMO</H3>
                <P>1º SÁVIO LEON DE LIMA PERDIGÃO - CLUB PINA/CARLOS HOLANDA</P>

                <br />
                <H3>INFANTIL 2 FEM GRADUADO PLUMA, -33,2 KG</H3>
                <P>1º GIOVANNA DA SILVA DIAS - ANIBAL</P>
                <P>2º LÍSLEN GABRIELLLY DE ALMEIDA SILVA - ANIBAL</P>

                <br />
                <H3>INFANTIL 2 FEM GRADUADO LEVE, -39,3 KG</H3>
                <P>1º HILLARY EMANUELLE DE SOUZA DE SOUZA - NABIL</P>
                <P>2º BRENDA FABIANA RODRIGUES RODRIGUES - CLUB PINA/CARLOS HOLANDA</P>

                <br />
                <H3>INFANTIL 2 FEM GRADUADO MEIO PESADO, -45,3 KG</H3>
                <P>1º ISABEL DA SILVA SANTOS - FJU BJJ</P>
                <P>2º ANA BEATRIZ CAVALCANTE SOUZA - NOVA UNIÃO</P>

                <br />
                <H3>INFANTIL 2 FEM GRADUADO PESADÍSSIMO</H3>
                <P>1º LILLIAN MARINHO NOGUEIRA - NLSJJ</P>
                <P>2º RANIELLY DE SOUZA CASTRO - NÔMADE BJJ</P>

                <br />
                <H3>INFANTIL 2 MASC GRADUADO PLUMA, -33,2 KG</H3>
                <P>1º PEDRO PHELIPE BEZERRA DA SILVA - GFTEAM</P>
                <P>2º ÂNGELO BRAYAN ROCHA MARQUES - GFTEAM</P>
                <P>3º YCARO SILVA DE ALBUQUERQUE - CHARDSON AMÂNCIO</P>

                <br />
                <H3>INFANTIL 2 MASC GRADUADO LEVE, -39,3 KG</H3>
                <P>1º BRUNO L UIGGI DOS SANTOS CASTRO - EQUIPE 7</P>
                <P>2º PAULO GABRIEL RAMOS - GFTEAM</P>
                <P>3º JOÃO VICTOR BARROS DE MAGALHÃES - ANIBAL</P>

                <br />
                <H3>INFANTIL 2 MASC GRADUADO MEIO PESADO, -45,3 KG</H3>
                <P>1º DIEGO VICTOR MOREIRA FONSECA - NABIL</P>
                <P>2º ISAAC MARQUES - GFTEAM</P>
                <P>3º MARCELO VINÍCIUS DELLARMELINA - CLUBE ORLEY LOBATO</P>

                <br />
                <H3>INFANTIL 2 MASC GRADUADO SUPER PESADO, -51,5 KG</H3>
                <P>1º DAVY ALVES GOMES - CLUB PINA/CARLOS HOLANDA</P>
                <P>2º SAMUEL SANTIGO BRAGA - CLUB PINA/CARLOS HOLANDA</P>

                <br />
                <H3>INFANTIL 2 MASC GRADUADO PESADÍSSIMO</H3>
                <P>1º YURI RYAN MOREIRA GUEDES - DOJO RAÇA</P>

                <br />
                <H3>INFANTIL 3 FEM GRADUADO PLUMA, -36,2 KG</H3>
                <P>1º KELLY KATHERINE LARES ESTABA - MELQUI GALVÃO</P>
                <P>2º ESTHER GONÇALVES - PROJ. SUÇUARANA</P>

                <br />
                <H3>INFANTIL 3 FEM GRADUADO LEVE, -44,3 KG</H3>
                <P>1º ANA REBECA COUTO SILVA - MELQUI GALVÃO</P>
                <P>2º RAFAELA SUZANA COELHO DA COSTA - TEAM CDL NO TATAME</P>
                <P>3º ELIZA RENATA LOPES DA COSTA - TEAM CDL NO TATAME</P>

                <br />
                <H3>INFANTIL 3 FEM GRADUADO MEIO PESADO, -52,5 KG</H3>
                <P>1º ANGELA VITORIA ALMEIDA DE SOUZA - EQUIPE 7</P>
                <P>2º LARISSA EDITH DA SILVA MOREIRA - NABIL</P>
                <P>3º ELOÍSA DIAS CASTRO - NORTH WARRIORS</P>

                <br />
                <H3>INFANTIL 3 FEM GRADUADO PESADÍSSIMO</H3>
                <P>1º ELOANE ROCHA DOS SANTOS - ANIBAL</P>
                <P>2º LAÍS MAYUMI DE OLIVEIRA OSAWA BOTELHO - MASATAKE OSAWA</P>

                <br />
                <H3>INFANTIL 3 MASC GRADUADO PLUMA, -36,2 KG</H3>
                <P>1º LUCAS VINICIUS SOARES FROTA - MELQUI GALVÃO</P>
                <P>2º JOÃO VITOR JACQUEMINUTH DE ARAÚJO - NABIL</P>
                <P>3º LUCAS HENRIQUE DE SOUZA MEIRELES - LUCIVAM ARAÚJO</P>

                <br />
                <H3>INFANTIL 3 MASC GRADUADO LEVE, -44,3 KG</H3>
                <P>1º MATHEUS SILVA DOS SANTOS - PROJ. JOVENS EMBAIXADORES BJJ</P>
                <P>2º ENZO KAUE SIQUIERA DOS SANTOS - PROJ. PAI E FILHO</P>
                <P>3º ERYCCK KAYO DA SILVA FONTES - CLUB PINA/CARLOS HOLANDA</P>
                <P>4º PEDRO HENRIQUE CHAGAS DOS SANTOS - ASS. MONTEIRO</P>

                <br />
                <H3>INFANTIL 3 MASC GRADUADO MEIO PESADO, -52,5 KG</H3>
                <P>1º JHON KENNED SOUZA SILVA JÚNIOR - CT ATRIO</P>
                <P>2º CAIO NASCIMENTO - PROJ. SUÇUARANA</P>
                <P>3º PEDRO HENRIQUE RODRIGUES CORTEZ - PROJ. JOVENS EMBAIXADORES BJJ</P>

                <br />
                <H3>INFANTIL 3 MASC GRADUADO SUPER PESADO, -60,5 KG</H3>
                <P>1º JAIRO LEVI ARTRICLINO ALMEIDA DA SILVA LIMA - CLUB PINA/CARLOS HOLANDA</P>

                <br />
                <H3>INFANTIL 3 MASC GRADUADO PESADÍSSIMO</H3>
                <P>1º DAVI LANHELLAS RODRIGUES - TEAM SIDNEY</P>
                <P>2º YAN MISAKI RODRIGUES DE SENA - MELQUI GALVÃO</P>

                <br />
                <H3>INFANTO JUVENIL 1 FEM GRADUADO PLUMA, -40,3 KG</H3>
                <P>1º PAMELLA JUSSARA - PROJ. SUÇUARANA</P>
                <P>2º ISABELA DA SILVA SANTOS - FJU BJJ</P>

                <br />
                <H3>INFANTO JUVENIL 1 FEM GRADUADO LEVE, -48,3 KG</H3>
                <P>1º HADASSA LIMA CRISÓSTOMO DE SOUZA - MELQUI GALVÃO</P>
                <P>2º DHASYLLA GOMES DA COSTA - PROJ. JOVENS EMBAIXADORES BJJ</P>
                <P>3º NATASHA KLISSIA COSTA MOREIRA - PROJ. ANJOS DA GUARDA</P>

                <br />
                <H3>INFANTO JUVENIL 1 FEM GRADUADO MEIO PESADO, -56,5 KG</H3>
                <P>1º LÍVIA MEDEIROS CARDOSO - ISMAEL GOMES</P>
                <P>2º MARIA CLARA SOARES - MELQUI GALVÃO</P>
                <P>3º MARIA EDUARDA LIMA - ANIBAL</P>

                <br />
                <H3>INFANTO JUVENIL 1 FEM GRADUADO PESADÍSSIMO</H3>
                <P>1º STHEFANNY SOPHIA GUEDES VERAS DO CANTO - CT ATRIO</P>
                <P>2º ANA CLARA OLIVEIRA DE SOUZA - OCTAVIO ALMEIDA BJJ</P>

                <br />
                <H3>INFANTO JUVENIL 1 MASC GRADUADO PLUMA, -40,3 KG</H3>
                <P>1º GABRIEL DOS SANTOS DA COSTA - ANIBAL</P>
                <P>2º WILLYCE FELIPE GUEDES DE OLIVEIRA - NABIL</P>
                <P>3º RONEY DOS ANJOS SILVA - ISMAEL GOMES</P>

                <br />
                <H3>INFANTO JUVENIL 1 MASC GRADUADO LEVE, -48,3 KG</H3>
                <P>1º JOÃO VITOR NASCIMENTO COELHO - PROJ. PAI E FILHO</P>
                <P>2º KYURI DA SILVA CUNHA - CLUBE ORLEY LOBATO</P>

                <br />
                <H3>INFANTO JUVENIL 1 MASC GRADUADO MEIO PESADO, -56,5 KG</H3>
                <P>1º JAIME LOPES MEIRELES - NABIL</P>
                <P>2º PAULO MESSIAS ACIOLE - MELQUI GALVÃO</P>
                <P>3º JOÃO GABRIEL DOS SANTOS CARVALHO - EQUIPE FERNANDO SOARES</P>

                <br />
                <H3>INFANTO JUVENIL 1 MASC GRADUADO SUPER PESADO, -65,0 KG</H3>
                <P>1º RICKSON RYAN - PROJ. SUÇUARANA</P>
                <P>2º ABNER EDUARDO PARENTE TRINDADE - LUCIVAM ARAÚJO</P>

                <br />
                <H3>INFANTO JUVENIL 1 MASC GRADUADO PESADÍSSIMO</H3>
                <P>1º JADSON LAYNON - PROJ. SUÇUARANA</P>
                <P>2º MARCO HENRIQUE DIAS SUDARIO - PROJ. JOVENS EMBAIXADORES BJJ</P>

                <br />
                <H3>INFANTO JUVENIL 2 FEM GRADUADO PLUMA, -44,3 KG</H3>
                <P>1º ALICE DANIELLY ALEXANDRE OLIVEIRA DA SILVA - CLUBE ORLEY LOBATO</P>
                <P>2º LORRANY VITÓRIA DA SILVA DE SOUZA - PROJ. JOVENS EMBAIXADORES BJJ</P>
                <P>3º ANANDRIA FLAVINE DA CRUZ COSTA - CLUB PINA/CARLOS HOLANDA</P>

                <br />
                <H3>INFANTO JUVENIL 2 FEM GRADUADO LEVE, -52,5 KG</H3>
                <P>1º ANABELLE ÉVANY - PROJ. SUÇUARANA</P>
                <P>2º ANALIS ARAÚJO E SILVA SOARES - CLUB PINA/CARLOS HOLANDA</P>
                <P>3º WILLIANY ISABELLA LAMEIRA DA SILVA - BROTHERS COMPANY</P>

                <br />
                <H3>INFANTO JUVENIL 2 FEM GRADUADO MEIO PESADO, -60,5 KG</H3>
                <P>1º ISABELY LUANA LOPES DA COSTA - TEAM CDL NO TATAME</P>
                <P>2º RAFAELA DE SOUZA NASCIMENTO - GFTEAM MANAUS</P>
                <P>3º MARIA CLARA TÁVORA PINHEIRO - RIBEIRO JIU JITSU</P>

                <br />
                <H3>INFANTO JUVENIL 2 FEM GRADUADO PESADÍSSIMO</H3>
                <P>1º ANA RAQUEL ANTUNES DA SILVA - RIBEIRO JIU JITSU</P>
                <P>2º ISABELE DANTAS SOUZA - MELQUI GALVÃO</P>
                <P>3º LARISSA RODRIGUES DE AZEVEDO - ANIBAL</P>

                <br />
                <H3>INFANTO JUVENIL 2 MASC GRADUADO PLUMA, -44,3 KG</H3>
                <P>1º DYLAN DANIEL LARES ESTABA - CLUB PINA/CARLOS HOLANDA</P>
                <P>2º DAVI SILVA NASCIMENTO - CLUB PINA/CARLOS HOLANDA</P>
                <P>3º DAVI BRAGA DE OLIVEIRA - MELQUI GALVÃO</P>

                <br />
                <H3>INFANTO JUVENIL 2 MASC GRADUADO LEVE, -52,5 KG</H3>
                <P>1º LUCAS SOUZA SILVA - MELQUI GALVÃO</P>
                <P>2º LUIZ MIGUEL ANDRADE SENA - MELQUI GALVÃO</P>
                <P>3º WENDERSON HENRIQUE MAIA - NABIL</P>

                <br />
                <H3>INFANTO JUVENIL 2 MASC GRADUADO MEIO PESADO, -60,5 KG</H3>
                <P>1º HALLYSSON GERMANO FARIAS MARCELINO - MELQUI GALVÃO</P>
                <P>2º DÉLRIKSON RYAN LEÃO MAIA - AMAZONAS TOP TEAM</P>
                <P>2º LEVY DE LEMOS FERREIRA - CLUB PINA/CARLOS HOLANDA</P>

                <br />
                <H3>INFANTO JUVENIL 2 MASC GRADUADO SUPER PESADO, -69,0 KG</H3>
                <P>1º GABRIEL BRENO DA SILVA - MELQUI GALVÃO</P>
                <P>2º PEDRO HENRIQUE ANTELA - MELQUI GALVÃO</P>
                <P>3º VICTOR GABRIEL FARIAS DA SILVA - CATIVO TEAM</P>

                <br />
                <H3>INFANTO JUVENIL 2 MASC GRADUADO PESADÍSSIMO</H3>
                <P>1º GABRIEL AQUILES DE SOUZA BARBOZA - WHITE HOUSE</P>

                <br />
                <H3>INFANTO JUVENIL 3 FEM GRADUADO PLUMA, -48,3 KG</H3>
                <P>1º MELISSA PÉROLA NATAL - NABIL</P>
                <P>2º THZINHABJJ - P. ATLETA DO PAI</P>
                <P>3º EMILLY KAROLINY ABREU DA SILVA - PROJ. JOVENS EMBAIXADORES BJJ</P>

                <br />
                <H3>INFANTO JUVENIL 3 FEM GRADUADO LEVE, -56,5 KG</H3>
                <P>1º ANA CAROLINA DE LIMA SOUZA - MELQUI GALVÃO</P>
                <P>2º SAMANTHA ISABELLA DE LIMA MORAIS - CLUB PINA/CARLOS HOLANDA</P>
                <P>3º DAIANA ARAUJO PONTES - CLUB PINA/CARLOS HOLANDA</P>

                <br />
                <H3>INFANTO JUVENIL 3 FEM GRADUADO MEIO PESADO, -65,0 KG</H3>
                <P>1º MARIA CECILYA RODRIGUEZ FREITAS - GFTEAM</P>
                <P>2º JOHARA LIMA MONTEIRO - ASS. MONTEIRO</P>
                <P>3º LAYZ EMANUELLE ARAÚJO DA SILVA - PROJ. JOVENS EMBAIXADORES BJJ</P>

                <br />
                <H3>INFANTO JUVENIL 3 FEM GRADUADO PESADÍSSIMO</H3>
                <P>1º YASMIM SOUZA DOURANTH - ANIBAL</P>
                <P>2º MARIA CLARA DOS SANTOS NASCIMENTO - BROTHERS COMPANY</P>
                <P>3º ANALICE MELO - OCTAVIO ALMEIDA BJJ</P>

                <br />
                <H3>INFANTO JUVENIL 3 MASC GRADUADO PLUMA, -48,3 KG</H3>
                <P>1º GABRIEL KALIL GOMES CASTRO - PROJ. JOVENS EMBAIXADORES BJJ</P>
                <P>2º VITOR EMANUEL DE SOUZA GOMES - CARLOS HOLANDA</P>
                <P>3º SEBASTIAN ANTÔNIO CASTANEDA - MELQUI GALVÃO</P>

                <br />
                <H3>INFANTO JUVENIL 3 MASC GRADUADO LEVE, -56,5 KG</H3>
                <P>1º DAVI PEREIRA DO NASCIMENTO - PROJ. CÉSINHA BRASILIAM JIU-JITSU</P>
                <P>2º ANDERSON COSTA DA SILVA - WHITE HOUSE</P>
                <P>3º ÍCARO BERNARDO SOUZA DE SALES - P. ATLETA DO PAI</P>

                <br />
                <H3>INFANTO JUVENIL 3 MASC GRADUADO MEIO PESADO, -65,0 KG</H3>
                <P>1º AKASSYL DIAS - CLUB PINA/CARLOS HOLANDA</P>
                <P>2º DEIVISON DE SOUZA FERNANDESS - DOJO RAÇA</P>
                <P>3º GUILHERME SILVA DE SOUZA - OCTAVIO ALMEIDA BJJ</P>

                <br />
                <H3>INFANTO JUVENIL 3 MASC GRADUADO SUPER PESADO, -73,0 KG</H3>
                <P>1º JÚLIO CESA FERNANDES SANTOS - IVO NETO JIU JITSU</P>
                <P>2º VITOR GABRIEL CORREA DA SILVA - COHEN BJJ</P>
                <P>3º MATHEUS ARAÚJO DA SILVA - LUCIVAM ARAÚJO</P>

                <br />
                <H3>INFANTO JUVENIL 3 MASC GRADUADO PESADÍSSIMO</H3>
                <P>1º DIOGO FERREIRA PEREIRA - NABIL</P>
                <P>2º NATANAEL VICTOR FERREIRA BARROS - GADITAS</P>
                <P>3º GABRIEL DA SILVA ROCHA - DOJO RAÇA</P>

                <br />
                <H3>JUVENIL 1 MASC AZUL PLUMA, -53,5 KG</H3>
                <P>1º SAMUEL ULISES MENEZES DE OLIVEIRA - MELQUI GALVÃO</P>
                <P>2º ISAQUE ASAEL DE SOUZA GOMES - MELQUI GALVÃO</P>
                <P>3º JAILSON OLIVEIRA BATISTA JUNIOR - CLUB PINA/CARLOS HOLANDA</P>

                <br />
                <H3>JUVENIL 1 MASC AZUL LEVE, -64,0 KG</H3>
                <P>1º PABLO MATHEUS DOS SANTOS CORREA - NABIL</P>
                <P>2º RAFAEL BESSA DE CARVALHO - PROJ. JOVENS EMBAIXADORES BJJ</P>
                <P>3º DANIEL NUNES DE OLIVEIRA - RIBEIRO JIU JITSU</P>

                <br />
                <H3>JUVENIL 1 MASC AZUL MEIO PESADO, -74,0 KG</H3>
                <P>1º LEONARDO MAGALHÃES GOMES - PROJ. JOVENS EMBAIXADORES BJJ</P>
                <P>2º FÁBIO RYAN FERREIRA DE SOUZA - CLUB PINA</P>
                <P>3º NATANAEL GUIMARÃES MACHADO - CLUB PINA</P>

                <br />
                <H3>JUVENIL 1 MASC AZUL SUPER PESADO, -84,3 KG</H3>
                <P>1º TONY GUILHERME DO NASCIMENTO - GFTEAM NORTE FIGTH</P>
                <P>2º GUILHERME ASAFE SOARES DOS SANTOS - LUCIVAM ARAÚJO</P>
                <P>3º FELIX JUNIO GOMES LOPES - GFTEAM</P>

                <br />
                <H3>JUVENIL 1 MASC AZUL PESADÍSSIMO</H3>
                <P>1º MATHEUS ARAUJO DE FREITAS - LUCIVAM ARAÚJO</P>
                <P>2º SALOMÃO PEREIRA SOARES - MCF JIU JITSU</P>
                <P>3º MÁRCIO GABRIEL SILVA COELHO - GFTEAM</P>

                <br />
                <H3>JUVENIL 2 MASC AZUL PLUMA, -58,5 KG</H3>
                <P>1º SAMUEL RIBEIRO BATISTA - CARLOS HOLANDA</P>
                <P>2º GUSTAVO ANDRES MACEDO ANGULO - CARLOS HOLANDA</P>
                <P>3º HENDRIL DA CRUZ COSTA - CLUB PINA/CARLOS HOLANDA</P>

                <br />
                <H3>JUVENIL 2 MASC AZUL LEVE, -69,0 KG</H3>
                <P>1º VITOR DE LIMA BRAGA - MELQUI GALVÃO</P>
                <P>2º HENRIQUE JUNIOR MARQUES DE CARVALHO - CHARDSON AMÂNCIO</P>
                <P>3º CARLOS EDUARDO GOMES TORRES - NABIL</P>

                <br />
                <H3>JUVENIL 2 MASC AZUL MEIO PESADO, -79,3 KG</H3>
                <P>1º LUCAS VINICIUS LEITE E LEITE - ASS. MONTEIRO</P>
                <P>2º GUILHERME CASTRO ARCOS - CARLOS HOLANDA</P>
                <P>3º MATHEUS VINICIUS PARENTE CAMPOS - PROJ. JOVENS EMBAIXADORES BJJ</P>

                <br />
                <H3>JUVENIL 2 MASC AZUL SUPER PESADO, -89,3 KG</H3>
                <P>1º ALEX XAVIER ASUAJE LOPEZ - NABIL</P>
                <P>2º MARCIO GALUCIO MONTEIRO JUNIOR - GFTEAM</P>
                <P>3º ALEX SANDER DOS SANTOS MARTINS - CT ATRIO</P>

                <br />
                <H3>JUVENIL 2 MASC AZUL PESADÍSSIMO</H3>
                <P>1º LUÍS EDUARDO BARROS PORTO - CLUB PINA/CARLOS HOLANDA</P>
                <P>2º PAULO HENRIQUE RODRIGUES DE AZEVEDO - ANIBAL</P>
                <P>3º LUIS EDUARDO DA SILVA E SILVA - NABIL</P>

                <br />
                <H3>JUVENIL 1 E 2 FEM AZUL PLUMA, -48,3 KG</H3>
                <P>1º RAISSA DE SOUZA PEREIRA - LUCIVAM ARAÚJO</P>
                <P>2º WHATINA RAYANNE DA SILVA LOPES - NOVOS SAMURAIS</P>
                <P>3º GEOVANA NASCIMENTO BARROS - GLADIADORES ABLE JIU JITSU</P>

                <br />
                <H3>JUVENIL 1 E 2 FEM AZUL LEVE, -56,5 KG</H3>
                <P>1º MARIA EDUARDA AGUIAR MARTIM - CLUB PINA/CARLOS HOLANDA</P>
                <P>2º MEYSSE RAYANNE DA ROCHA CAMPOS - CLUBE ORLEY LOBATO</P>
                <P>3º JENIFER YASMIM RIBEIRO JATI - PROJ. PAI E FILHO</P>

                <br />
                <H3>JUVENIL 1 E 2 FEM AZUL MEIO PESADO, -65,0 KG</H3>
                <P>1º VIVIAN JHULY NASCIMENTO MENEZES - CT ATRIO</P>
                <P>2º AVRIL KAROLINE DE SOUZA OLIVEIRA - CT ATRIO</P>
                <P>3º RAMILE OLIVEIRA - CT ATRIO</P>

                <br />
                <H3>JUVENIL 1 E 2 FEM AZUL SUPER PESADO, -73,0 KG</H3>
                <P>1º KELLYANA SOUSA DE OLIVEIRA - ANIBAL</P>
                <P>2º MARIA EDUARDA SOARES LEÃO - RIBEIRO JIU JITSU</P>

                <br />
                <H3>JUVENIL 1 E 2 FEM AZUL PESADÍSSIMO</H3>
                <P>1º KHINDRYA JAYANE COSTA DOS SANTOS - RIBEIRO JIU JITSU</P>
                <P>2º RAYELLEM DANNY DA COSTA OLIVEIRA - CT ATRIO</P>
                <P>3º TARCIANE DO NASCIMENTO PAZ - NLSJJ</P>

                <br />
                <H3>ADULTO FEM AZUL ABSOLUTO</H3>
                <P>1º JENIFFER EDUARDA DOS SANTOS TENÓRIO - CLUB PINA/CARLOS HOLANDA</P>
                <P>2º KACIA LIMA GOMES - KRATOS</P>
                <P>3º ADRIELLY GOBERTO DE JESUS MACEDO - CT JESUS BROTHERS</P>

                <br />
                <H3>ADULTO FEM ROXA ABSOLUTO</H3>
                <P>1º NATHALY BARBOSA - CLUB PINA/CARLOS HOLANDA</P>
                <P>2º VICTORIA VIEIRA NERES - NABIL</P>

                <br />
                <H3>ADULTO FEM MARROM ABSOLUTO</H3>
                <P>1º REGIANE SOUZA DA SILVA - CARIOCA ACADEMY</P>
                <P>2º ALINE PINHEIRO DA SILVA - LUCIVAM ARAÚJO</P>
                <P>3º ISMAELE MOURA - CLUB PINA/CARLOS HOLANDA</P>
                <P>4º THALITA SAMPAIO - NABIL</P>

                <br />
                <H3>ADULTO FEM PRETA ABSOLUTO</H3>
                <P>1º JEANE LOPES MARICAUA - CLUBE ORLEY LOBATO</P>
                <P>2º HANNA BEATRIZ TAVARES CARVALHO - MELQUI GALVÃO</P>

                <br />
                <H3>ADULTO MASC AZUL PENA, -70,0 KG</H3>
                <P>1º CAUÃ RICKSON OLIVEIRA CUNHA - CLUBE ORLEY LOBATO</P>
                <P>2º JULIANO DA SILVA SARAIVA - CLUB PINA/CARLOS HOLANDA</P>
                <P>3º JOSÉ ERICK SANTOS DOS SANTOS - CLUBE ORLEY LOBATO</P>

                <br />
                <H3>ADULTO MASC AZUL MEIO PESADO, -88,3 KG</H3>
                <P>1º JOÃO VICTOR DE SOUZA MACEDO - DREAM ART</P>
                <P>2º VITOR PANTOJA DE OLIVEIRA - NABIL</P>

                <br />
                <H3>ADULTO MASC AZUL PESADÍSSIMO</H3>
                <P>1º ALESSANDRO ROBERTO MELO AMORIM - CHECKMAT</P>
                <P>2º DAVI AMARAL FERREIRA - PROJ. JOVENS EMBAIXADORES BJJ</P>
                <P>3º SÉRGIO AUGUSTO CARVALHO MARTINS - CLUB PINA/CARLOS HOLANDA</P>

                <br />
                <H3>ADULTO MASC ROXA PENA, -70,0 KG</H3>
                <P>1º DAVI YURI DA SILVA MARTINS - CLUB PINA/CARLOS HOLANDA</P>
                <P>2º PEDRO HENRIQUE RAMOS DE OLIVEIRA - ASS. MONTEIRO</P>
                <P>3º ARMANDO KAUE LOPES GONCALVES - JUAREZ HARLES</P>

                <br />
                <H3>ADULTO MASC ROXA MEIO PESADO, -88,3 KG</H3>
                <P>1º JEFERSON PAULO DA SILVA E SILVA - CARLOS HOLANDA</P>
                <P>2º RAILSON DOS SANTOS VASCONCELOS - NABIL</P>
                <P>3º LUIS MIGUEL DE SOUZA ALMEIDA - NABIL</P>

                <br />
                <H3>ADULTO MASC ROXA PESADÍSSIMO</H3>
                <P>1º ORLANDO SILVA SARMENTO DIAS NETO - ASS. MONTEIRO</P>
                <P>2º ATHIRSON LINS BATISTA - EQUIPE 7</P>
                <P>3º CHRISTOPHER CARVALHO DA SILVA - CLUB PINA/CARLOS HOLANDA</P>

                <br />
                <H3>ADULTO MASC MARROM PENA, -70,0 KG</H3>
                <P>1º CLENILDO GONÇALVES DA SILVA JUNIOR - WHITE HOUSE</P>
                <P>2º LUKAS FREITAS - CLUB PINA/CARLOS HOLANDA</P>
                <P>3º MATHEUS CAVALCANTE DE OLIVEIRA - ANJOS DO TATAME</P>

                <br />
                <H3>ADULTO MASC MARROM MEIO PESADO, -88,3 KG</H3>
                <P>1º ARCANGELO OLIVEIRA SOARES - NAKAY FIGHT ACADEMY</P>
                <P>2º SAMUEL SILVA DA SILVA - WHITE HOUSE</P>
                <P>3º RICHARD DO ROSARIO CAMPOS - NABIL</P>

                <br />
                <H3>ADULTO MASC MARROM PESADÍSSIMO</H3>
                <P>1º RICARDO MOSSAMBITE ISAÍAS - AGBJJ</P>
                <P>2º JADSON EDUARDO FERNANDES GUEDES - GFTEAM NORTE FIGTH</P>
                <P>3º JOSÉ RICARDO AURELIANO DE OLIVEIRA - RIBEIRO JIU JITSU</P>

                <br />
                <H3>ADULTO MASC PRETA PENA, -70,0 KG</H3>
                <P>1º TIAGO MACEDO DE CARVALHO - GRACIE BARRA</P>
                <P>2º NATHANNAEL JACKSON FONSECA FERNANDES - KVRA TOP TEAM</P>
                <P>3º RODRIGO OTÁVIO FERREIRA DE OLIVEIRA - CLUB PINA/CARLOS HOLANDA</P>

                <br />
                <H3>ADULTO MASC PRETA MEIO PESADO, -88,3 KG</H3>
                <P>1º CARLOS ALBERTO DIAS PEREIRA - DENIS WESTER</P>
                <P>2º LUIZ ANDRÉ MATIAS DA SILVA - CARLOS HOLANDA</P>
                <P>3º GUILHERME JONES FONSECA FERNANDES - CLUB PINA/CARLOS HOLANDA</P>

                <br />
                <H3>ADULTO MASC PRETA PESADÍSSIMO</H3>
                <P>1º HEBERT DOS SANTOS - AMAZONAS TOP TEAM</P>
                <P>2º JOSÉ SILVA CARDOSO FILHO - NABIL</P>
                <P>3º CLEUMILTON CORREA DAS NEVES NETO - NOVA UNIÃO</P>

                <br />
                <H3>MASTER 1 FEM AZUL ABSOLUTO</H3>
                <P>1º MARCIETE FERNANDES DELLARMELINA - CLUBE ORLEY LOBATO</P>
                <P>2º ALCIONE MENEZES FONSECA - GFTEAM NORTE FIGTH</P>
                <P>3º NELMARA DA SILVA DIAS - LUÍS NETO</P>

                <br />
                <H3>MASTER 1 FEM ROXA ABSOLUTO</H3>
                <P>1º KARLEY ROSIANE ANTUNES COSTA - RIBEIRO JIU JITSU</P>
                <P>2º ROSANNY NUNES DUTRA - KRATOS</P>

                <br />
                <H3>MASTER 1 FEM MARROM ABSOLUTO</H3>
                <P>1º ANDREZA DA COSTA CARVALHO - KRATOS</P>

                <br />
                <H3>MASTER 1 FEM PRETA ABSOLUTO</H3>
                <P>1º JULIANA GONÇALVES DE SOUZA ALEIXO - CLUB PINA/CARLOS HOLANDA</P>
                <P>2º THAÍS SANTOS SOUSA NASCIMENTO - CLUBE ORLEY LOBATO</P>
                <P>3º LIVIA MAIA MENDONCA - AJ JIU JITSU</P>

                <br />
                <H3>MASTER 1 MASC AZUL ABSOLUTO</H3>
                <P>1º HANS IM GLUCK YOUNG FILHO - WHITE HOUSE</P>
                <P>2º ARLISON SILVA MONTEIRO - CLUB PINA/CARLOS HOLANDA</P>

                <br />
                <H3>MASTER 1 MASC ROXA ABSOLUTO</H3>
                <P>1º HELIO - LIONS TEAM</P>
                <P>2º THIAGO ANDRADE SEABRA - EQUIPE FERNANDO SOARES</P>
                <P>3º WILLIE PEREIRA PINHEIRO - KRATOS</P>

                <br />
                <H3>MASTER 1 MASC MARROM ABSOLUTO</H3>
                <P>1º RENAN SOUSA BEZERRA - BROTHERS COMPANY</P>
                <P>2º ADENILSON DA SILVA RIBEIRO - GRACIE BARRA</P>
                <P>3º MEDSON FRANCISCO BRUCE DE SOUZA - CLUB PINA/CARLOS HOLANDA</P>

                <br />
                <H3>MASTER 1 MASC PRETA ABSOLUTO</H3>
                <P>1º YCARO RIBEIRO LIMA - DAILSON PINHEIRO</P>
                <P>2º EDUARDOPAIVACRUZ - CLUB PINA/CARLOS HOLANDA</P>
                <P>3º CLAUDEVAN MARTINS DE OLIVEIRA - ASS. MONTEIRO</P>

                <br />
                <H3>MASTER 2 MASC PRETA ABSOLUTO</H3>
                <P>1º TED TARSSIS BORGES BRAGA - CLUBE ORLEY LOBATO</P>
                <P>2º MARCELO DE LIMA COSTA - CLUB PINA/CARLOS HOLANDA</P>
                <P>3º HERMES PEREIRA CHAGAS NETO - KRATOS</P>

                <br />
                <H3>MASTER 3 MASC PRETA ABSOLUTO</H3>
                <P>1º FRANCISCO RAFAEL BARROSO - ANIBAL</P>
                <P>2º JOSÉ ARAÚJO CORDEIRO - ANIBAL</P>

                <br />
                <H3>MASTER 4 MASC PRETA ABSOLUTO</H3>
                <P>1º ESTEVAM FERREIRA DA COSTA JUNIOR - ANDRÉ POSSATO BJJ</P>
                <P>2º ANDRÉ LUIZ DOS SANTOS ALENCAR - BROTHERS COMPANY</P>

                <br />
                <H3>MASTER 5 E 6 MASC PRETA ABSOLUTO</H3>
                <P>1º FRANK FRANCO DE LIMA - CLUBE ORLEY LOBATO</P>
                <P>2º MARCELO ZANYS FERREIRA DE OLIVEIRA - CARIOCA ACADEMY</P>

              </Collapse> */}


            </Content>
          </TournamentActions>
        </TournamentDescription>
      </Container>
      <Footer />
    </>
  );
};

export default SeletivaManausOlimpica2024;
