import { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import logoImg from "../../../assets/images/logo.png";
import useAuthenticate from "../../../hooks/useAuthenticate";
import {
  CloseMenuButton,
  Container,
  Navbar,
  NavbarLogo,
  NavbarMenu,
  NavbarMenuIcon,
  NavbarMenuItem,
  NavbarMenuLink,
} from "./styles";

const NavBar: React.FC = () => {
  const { authenticated, destroyToken } = useAuthenticate();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);
  const closeMenu = () => setIsOpen(false);

  const logout = () => {
    // Limpeza geral
    destroyToken();
    localStorage.clear();
    sessionStorage.clear();

    // Redireciona para o cadastro ou página inicial
    navigate("/"); // ou "/login" se preferir
  };

  return (
    <Navbar>
      <Container>
        <Link to={"/"}>
          <NavbarLogo src={logoImg} />
        </Link>
        <NavbarMenu isOpen={isOpen}>
          <CloseMenuButton onClick={closeMenu}>
            <FaTimes />
          </CloseMenuButton>
          {authenticated ? (
            <>
              <NavbarMenuItem>
                <Link to="/">
                  <NavbarMenuLink as="span">Torneios</NavbarMenuLink>
                </Link>
              </NavbarMenuItem>
              <NavbarMenuItem>
                <Link to="/profile">
                  <NavbarMenuLink as="span">Meu Perfil</NavbarMenuLink>
                </Link>
              </NavbarMenuItem>
              <NavbarMenuItem>
                <NavbarMenuLink as="span" onClick={logout}>
                  Logout
                </NavbarMenuLink>
              </NavbarMenuItem>
            </>
          ) : (
            <>
              <NavbarMenuItem>
                <Link to="/">
                  <NavbarMenuLink as="span">Torneios</NavbarMenuLink>
                </Link>
              </NavbarMenuItem>
              <NavbarMenuItem>
                <Link to="/login">
                  <NavbarMenuLink as="span">Login</NavbarMenuLink>
                </Link>
              </NavbarMenuItem>
              <NavbarMenuItem>
                <Link to="/register">
                  <NavbarMenuLink as="span">Registrar</NavbarMenuLink>
                </Link>
              </NavbarMenuItem>
            </>
          )}
        </NavbarMenu>
        <NavbarMenuIcon onClick={toggleMenu}>
          {isOpen ? <FaTimes /> : <FaBars />}
        </NavbarMenuIcon>
      </Container>
    </Navbar>
  );
};

export default NavBar;
