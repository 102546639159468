import Highlighter from "react-highlight-words"; // Importando a biblioteca
import {
  Container,
  Content,
  FilterContainer,
  InfoContainerText,
  PageLocationTitle,
  PageTitle,
  PageTitleBold,
  PageTitleContainer,
  SubscriptionContainer,
  SubscriptionContent,
  SubscriptionDescription,
  SubscriptionItem,
  SubscriptionList,
  SubscriptionTitle,
} from "./styles";
import {
  Field,
  FilterOption,
  FilterSelect,
} from "src/components/atoms/input/inputText";
import CollapseText from "src/components/atoms/colapso-text";
import whatsapp from "../../assets/images/wpp.png";
import NavBar from "src/components/organisms/navbar";
import { useState } from "react";

const ListSubscriptionEventsByOwner = ({
  filter,
  setFilter,
  data,
  tournaments,
  search,
  setSearch,
  renderItems,
  account = null,
}: any) => {
  const [statusFilter, setStatusFilter] = useState<string | null>(null);

  const filteredArrayStatusTrue = data.filter(
    (value: any) => value.status === true
  );

  const filteredArrayStatusFalse = data.filter(
    (value: any) => value.status === false
  );

  const openWhatsapp = (phone: string) => {
    window.open(`https://whatsa.me/${phone}`, "_blank");
  };

  const informacoesDePagamento = filteredArrayStatusTrue.reduce(
    (acm: any, cur: any) => {
      const value = Number.parseFloat(cur.valueSubscription);
      if (!isNaN(value)) {
        acm.total += value;
        acm.count++;

        if (acm.valuesPaid[String(value)]) {
          acm.valuesPaid[String(value)]++;
        } else {
          acm.valuesPaid[String(value)] = 1;
        }
      } else {
        if (acm.others[cur.valueSubscription.toUpperCase()]) {
          acm.others[cur.valueSubscription.toUpperCase()]++;
        } else {
          acm.others[cur.valueSubscription.toUpperCase()] = 1;
        }
      }
      return acm;
    },
    { total: 0, count: 0, others: {}, valuesPaid: {} }
  );

  const moneyFormat = (value: number) => {
    return new Intl.NumberFormat("pt-br", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const filteredData = data
    .filter((value: any) => {
      if (statusFilter === "true") return value.status === true;
      if (statusFilter === "false") return value.status === false;
      return true;
    })
    .filter((value: any) =>
      search
        ? String(value.id).includes(search) ||
          (value.fighters
            ? value.fighters?.name.toLowerCase().includes(search.toLowerCase())
            : value.user?.name.toLowerCase().includes(search.toLowerCase())) ||
          (value.fighters
            ? value.fighters?.phone.includes(search)
            : value.user?.phone.includes(search)) ||
          value.genre.toLowerCase().includes(search.toLowerCase()) ||
          (value.fighters ? false : value?.age === search) ||
          value.category.toLowerCase().includes(search.toLowerCase()) ||
          value.belt.toLowerCase().includes(search.toLowerCase()) ||
          value.weight.toLowerCase().includes(search.toLowerCase()) ||
          (value.fighters
            ? value.fighters?.gym.toLowerCase().includes(search.toLowerCase())
            : value.user?.gym.toLowerCase().includes(search.toLowerCase()))
        : true
    );

  return (
    <>
      <NavBar />
      <Container>
        <Content>
          <PageTitleContainer>
            <PageLocationTitle>Todas as Inscrições</PageLocationTitle>
            <h2>Campeonato {tournaments.name.toUpperCase()} </h2>
            <PageTitle>{data.length} inscrições encontradas</PageTitle>
            <PageTitle>
              {filteredArrayStatusFalse.length} inscrições não pagas
            </PageTitle>
            <PageTitle>
              <CollapseText
                title={`${filteredArrayStatusTrue.length} inscrições pagas`}
                styleWrapper={{ marginBottom: "16px" }}
              >
                {Object.entries(informacoesDePagamento.others).map(
                  ([key, value]) => (
                    <PageTitle key={key}>{`${value} - ${key}`}</PageTitle>
                  )
                )}
                {Object.entries(informacoesDePagamento.valuesPaid).map(
                  ([key, value]) => (
                    <PageTitle key={key}>
                      {`${value} - R$ ${moneyFormat(
                        Number(key)
                      )} = R$ ${moneyFormat(Number(key) * Number(value))}`}
                    </PageTitle>
                  )
                )}
                <PageTitleBold>
                  <b style={{ fontWeight: "bold" }}>Valor Total:</b>
                </PageTitleBold>
                <PageTitle>
                  {informacoesDePagamento.count} - R${" "}
                  {moneyFormat(informacoesDePagamento.total)}
                </PageTitle>
              </CollapseText>
            </PageTitle>

            {data.length ? (
              <FilterContainer
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Field
                  filterValue=""
                  type="text"
                  value={search}
                  placeholder="REALIZAR FILTRO"
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ width: "100%" }}
                />

                <FilterSelect
                  selectedCategory={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                >
                  <FilterOption value="">Todos os pagamentos</FilterOption>
                  <FilterOption value="true">Pagamento efetuado</FilterOption>
                  <FilterOption value="false">Pagamento pendente</FilterOption>
                </FilterSelect>
              </FilterContainer>
            ) : null}
          </PageTitleContainer>

          <SubscriptionContainer>
            {filteredData.map((value: any, index: number) => (
              <SubscriptionList key={value.id}>
                <SubscriptionItem>
                  <SubscriptionContent>
                    <SubscriptionTitle>
                      Inscrição Nº {value.id} -{" "}
                      {value.tournament?.name?.toUpperCase() ||
                        value.tournamentName?.toUpperCase()}
                    </SubscriptionTitle>
                    <br />
                    <SubscriptionDescription>
                      <InfoContainerText>
                        <span>
                          <b>Nome:</b>{" "}
                          <Highlighter
                            highlightClassName="highlight"
                            searchWords={[search]}
                            autoEscape={true}
                            textToHighlight={
                              value.fighters
                                ? value.fighters.name
                                : value.user?.name
                            }
                          />
                        </span>
                        <span>
                          <b>Contato:</b>{" "}
                          {value.fighters !== null ? (
                            value.fighters.phone === "" ? (
                              "Sem contato "
                            ) : (
                              <a
                                href="#"
                                onClick={(
                                  e: React.MouseEvent<HTMLAnchorElement>
                                ) => {
                                  e.preventDefault();
                                  openWhatsapp(value.fighters.phone);
                                }}
                              >
                                <img
                                  src={whatsapp}
                                  alt="wpp"
                                  width={16}
                                  height={16}
                                  style={{ padding: "0 10px 0 10px" }}
                                />
                                <Highlighter
                                  highlightClassName="highlight"
                                  searchWords={[search]}
                                  autoEscape={true}
                                  textToHighlight={value.fighters.phone}
                                />
                              </a>
                            )
                          ) : value.user.phone === "" ? (
                            "Sem contato "
                          ) : (
                            <a
                              href="#"
                              onClick={(
                                e: React.MouseEvent<HTMLAnchorElement>
                              ) => {
                                e.preventDefault();
                                openWhatsapp(value.user.phone);
                              }}
                            >
                              <img
                                src={whatsapp}
                                alt="wpp"
                                width={16}
                                height={16}
                                style={{ padding: "0 10px 0 10px" }}
                              />
                              <Highlighter
                                highlightClassName="highlight"
                                searchWords={[search]}
                                autoEscape={true}
                                textToHighlight={value.user.phone}
                              />
                            </a>
                          )}{" "}
                        </span>
                        <span>
                          <b>Gênero:</b>{" "}
                          <Highlighter
                            highlightClassName="highlight"
                            searchWords={[search]}
                            autoEscape={true}
                            textToHighlight={value.genre?.toUpperCase()}
                          />
                        </span>
                        {/* {value.fighters ? (
                                                            value.fighters.age
                                                        ) : ( */}
                        <span>
                          <b>Idade de Luta:</b>{" "}
                          <Highlighter
                            highlightClassName="highlight"
                            searchWords={[search]}
                            autoEscape={true}
                            textToHighlight={`${value.age} anos`}
                          />
                        </span>
                        {/* )} */}
                        <span>
                          <b>Equipe: </b>{" "}
                          <Highlighter
                            highlightClassName="highlight"
                            searchWords={[search]}
                            autoEscape={true}
                            textToHighlight={
                              value.fighters
                                ? value.fighters.gym
                                : value.user.gym
                            }
                          />
                        </span>
                        <span>
                          <b>Categoria(s):</b>{" "}
                          <Highlighter
                            highlightClassName="highlight"
                            searchWords={[search]}
                            autoEscape={true}
                            textToHighlight={`${value.genre.toUpperCase()} (${value.category.toUpperCase()}) / ${value.belt.toUpperCase()} / ${
                              value.weight
                            }`}
                          />
                        </span>
                      </InfoContainerText>
                      {renderItems(value.slug, value.status, value.id, index)}
                    </SubscriptionDescription>
                  </SubscriptionContent>
                </SubscriptionItem>
              </SubscriptionList>
            ))}
          </SubscriptionContainer>
        </Content>
      </Container>
    </>
  );
};

export default ListSubscriptionEventsByOwner;
