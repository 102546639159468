import { useEffect, useState } from "react";
import useAuthenticate from "src/hooks/useAuthenticate";
import ListSubscriptionEventsByOwner from ".";
import { InfoContainerStatus, StatusButton } from "./styles";
import { useParams } from "react-router-dom";
import api from "src/services/api";
import { toast } from "react-toastify";

const SubscriptionOwner = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { account }: any = useAuthenticate();
  const [filter, setFilter] = useState<string | null>(null);
  const [tournament, setTournament] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");
  const { slug } = useParams();

  useEffect(() => {
    setLoading(true);

    Promise.all([
      api.get(`/tournaments/${slug}`).then(({ data }) => setTournament(data)),
      api
        .get(`/tournaments/enrollments/list-all/public/${slug}`)
        .then(({ data }) => setData(data)),
    ])
      .catch(() => {
        toast.error("Não foi possível carregar os dados do torneio.");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const renderItems = (id: number, status: any, index: number) => (
    <InfoContainerStatus key={index}>
      <StatusButton status={status}>
        <span>{status ? "Pagamento efetuado" : "Pendente de pagamento"}</span>
      </StatusButton>
    </InfoContainerStatus>
  );

  if (loading) {
    return <h2>Carregando...</h2>;
  }

  return (
    !loading && (
      <ListSubscriptionEventsByOwner
        filter={filter}
        setFilter={setFilter}
        data={data}
        tournaments={tournament}
        search={search}
        setSearch={setSearch}
        renderItems={renderItems}
        account={account}
      />
    )
  );
};

export default SubscriptionOwner;
