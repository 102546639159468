import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import { StyledButton, StyledTable } from "./styles";

const ReportDetailTournament = () => {
  const { slug } = useParams();
  const [data, setData] = useState([]);
  const apiUrl = `https://api.torneiofacil.com/tournaments/enrollments/list-all/public/`;
  useEffect(() => {
    console.log("slug", slug);
    const fetchReportDetailTournament = async () => {
      try {
        const response = await fetch(apiUrl + slug);
        const tournament = await response.json();
        const formattedData = formatData(tournament);
        setData(formattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchReportDetailTournament();
  }, [slug]);

  const formatData = (tournament: any) => {
    return tournament.map((item: any) => {
      const isAluno = item.fighters ? "Aluno" : "Professor/Usuário";
      const inscrito = item.fighters || item.user;
      const professorNome = item.fighters ? item.user.name : "-";

      return {
        id: item.id,
        nome: inscrito.name,
        idade: item.age,
        peso: item.weight,
        genero: item.genre,
        faixa: item.belt,
        categoria: item.category,
        gym: inscrito.gym,
        numero: inscrito.phone,
        dataDeNascimento: item.fighters
          ? item.fighters.birth_date
          : item.user.birth_date,
        status: item.status ? "Pago" : "Pendente",
        valorPago: item.valueSubscription,
        // role: isAluno,
        // studentId: item.fighters ? item.fighters.id : "-",
        nomeDoProfessor: professorNome,
      };
    });
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Torneio");
    XLSX.writeFile(workbook, "torneio_inscritos.xlsx");
  };

  return (
    <div>
      <h2>Relatório detalhado</h2>
      <h1>{slug}</h1>
      <StyledButton onClick={downloadExcel}>Baixar Excel</StyledButton>
      <StyledTable>
        <thead>
          <tr>
            <th>ID</th>
            <th>Nome</th>
            <th>Idade</th>
            <th>Peso</th>
            <th>Gênero</th>
            <th>Faixa</th>
            <th>Categoria</th>
            <th>Academia</th>
            <th>Telefone</th>
            <th>Data de Nascimento</th>
            <th>Status</th>
            <th>Valor Pago</th>
            <th>Nome do Professor</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row: any) => (
            <tr key={row.id}>
              {Object.values(row).map((value: any, index) => (
                <td key={index}>{value}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </div>
  );
};

export default ReportDetailTournament;
