import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div``;

export const Content = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
`;

export const TournamentContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 320px;
  background-color: rgb(255, 255, 255);
  position: relative;
  height: 64vh;
  max-height: 515px;
  min-height: 460px;
  background-image: url(${({ image }: { image: any }) => image});
  background-position: 50% 50%;
  background-size: 120%;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(24px) brightness(0.8);
  }
`;

export const TournamentContainerBanner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  position: relative;
  padding: 0px 32px;
  margin-top: 20px;
  height: 109%;
`;

export const TournamentContainerBannerCarrosel = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1440px;
  position: relative;
  padding: 0px 32px;
  margin-top: 20px;
  height: 109%;
`;

export const TournamentBanner = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  -webkit-box-pack: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: 0px auto;
  border-radius: 12px;
  background-image: url(${({ image }: { image: any }) => image});
  background-position: 50% 50%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: rgba(25, 31, 40, 0.2) 0px 20px 36px 0px;
`;

export const TournamentContent = styled.div`
  margin-top: -20px;
  padding: 100px 20px 40px 20px;
  background: #fff;
`;

export const Title = styled.h1`
  margin: 20px 0 0 0;
  padding: 0;
`;

export const Location = styled.p`
  margin: 10px 0 0 0;
  padding: 0;
`;

export const TournamentDescription = styled.div`
  padding: 0px 20px 60px;
`;

export const TournamentInstructionTitle = styled.h1``;

export const TournamentInstructions = styled.p``;

export const TournamentActions = styled.div``;

export const TournamentEnrollmentLink = styled(Link)`
  margin: 20px 15px 0 0;
  cursor: pointer;
  background: #ff5722;
  border-radius: 8px;
  padding: 12px 18px;
  border: none;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: rgb(255, 255, 255);
  display: inline-block;
  text-decoration: none;
  margin-top: 14px;
  font-weight: bold;
  width: 20%;
  text-align: center;
    @media (max-width: 370px) {
    width: 80%;
    text-align: center;
  }
  @media (max-width: 450px) {
    width: 80%;
    text-align: center;
  }
`;

export const TournamentEnrollmentLinkEndpage = styled(Link)`
   margin: 20px 15px 0 20px;
  cursor: pointer;
  background: #ff5722;
  border-radius: 8px;
  padding: 12px 18px;
  border: none;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: rgb(255, 255, 255);
  display: inline-block;
  text-decoration: none;
  margin-top: 14px;
  font-weight: bold;
  width: 20%;
  text-align: center;
  min-width: 160px;
  @media (max-width: 370px) {
    width: 80%;
    text-align: center;
    min-width: auto;
  }
  @media (max-width: 450px) {
    width: 80%;
    text-align: center;
    min-width: auto;
  }
`;

export const TournamentLink = styled.a`
  margin: 20px 15px 0 20px;
  cursor: pointer;
  background: rgb(55, 55, 55);
  border-radius: 8px;
  padding: 12px 18px;
  border: none;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: rgb(255, 255, 255);
  display: inline-block;
  text-decoration: none;
  margin-top: 14px;
  font-weight: bold;

  min-width: 160px;
  @media (max-width: 370px) {
    width: 80%;
    text-align: center;
    min-width: auto;
  }
  @media (max-width: 450px) {
    width: 80%;
    text-align: center;
    min-width: auto;
  }
`;

export const EditalContainer = styled.div`
    border: 1px solid #ccc;
    padding: 8px 8px 24px 8px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background: rgb(55, 55, 55);
    border-radius: 8px;
`;

export const EditalTitle = styled.h3`
    padding: 0 8px;
    background: rgb(55, 55, 55);
    border: none;
    font-size: 16px;
    text-align: start;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.01em;
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-weight: bold;
    word-break: break-word;
`;

export const EditalContent = styled.div`
    margin-top: 16px;
    display: block;
    padding: 0 8px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.01em;
    color: rgb(255, 255, 255);
    > a {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: -0.01em;
      text-decoration: none;
      color: rgb(255, 255, 255);
    }
    > h3 {
      line-height: 24px;
    }
`;

interface QRCodeImgProps {
  width: number;
}

export const QRCodeImg = styled.img<QRCodeImgProps>`
  width: ${(props) => props.width}px;
  max-width: 100%;
`;

export const Span = styled.span`
  margin: 15px 0 0 0;
  display: block;
  text-align: justify;
`;

export const H3 = styled.h3`
  margin: 5px 0 0px 0;
  padding: 0;
  text-align: justify;
`;

export const H5 = styled.h5`
  margin: 5px 0 0px 0;
  padding: 0;
  text-align: justify;
`;

export const P = styled.p`
  margin: 0;
  padding: 0;
  text-align: justify;
`;
