import React, { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import Collapse from "../../components/atoms/colapso";
import Footer from "../../components/organisms/footer";
import NavBar from "../../components/organisms/navbar";
import { FaMapMarkerAlt } from "react-icons/fa";
//import useAuthenticate from "../../hooks/useAuthenticate";
import {
  Container,
  Content,
  H3,
  H5,
  Location,
  P,
  QRCodeImg,
  Span,
  Title,
  TournamentActions,
  TournamentBanner,
  TournamentContainer,
  TournamentContainerBanner,
  TournamentContent,
  TournamentDescription,
  TournamentEnrollmentLink,
  TournamentEnrollmentLinkEndpage,
  TournamentLink,
  EditalContainer,
  EditalContent,
  EditalTitle,
} from "./styles";

import { SponsorContainer, SponsorList } from "src/components/atoms/sponsor-area/styles";
import SponsorButton from "src/components/atoms/sponsor-area";
import useAuthenticate from "../../hooks/useAuthenticate";

//Patrocinio
import torneio from "../../assets/images/sponsors/torneio-facil.png";
import delegadoPericlesDepultadoEstaual from "../../assets/images/sponsors/delegado-pericles-depultado-estaual.jpg";
import RobertoCidadeDepultadoEstaual from "../../assets/images/sponsors/roberto-cidade-deputado-estadual.png";
import fjjam from "../../assets/images/sponsors/fjjam.jpg";
import crEstilo from "../../assets/images/sponsors/cr-estilo.png";
import escolaCelus from "../../assets/images/sponsors/escola-celus.png";
import jdRefeicoesESorvetes from "../../assets/images/sponsors/jd-refeicoes-e-sorvetes.jpg";
import as from "../../assets/images/sponsors/as.jpg";
import oticasDiniz from "../../assets/images/sponsors/oticas-diniz.png";
import goldStar from "../../assets/images/sponsors/gold-star.jpeg";
import api from "../../services/api";


//Apoio
import govCascavel from "../../assets/images/sponsors/govCascavel.jpeg";
import govParana from "../../assets/images/sponsors/govParana.jpg";
import agitoTur from "../../assets/images/sponsors/agito-tur.jpeg";
import prediletaRestaurante from "../../assets/images/sponsors/predileta-restaurante.png";
import donaMadameBoutique from "../../assets/images/sponsors/dona-madame-boutique.jpeg";
import escoveSeVilaA from "../../assets/images/sponsors/escove-se-vila-a.jpeg";
import trevusMartialArts from "../../assets/images/sponsors/trevus-martial-arts.jpg";
import tabelaPeso from "../../assets/images/tournaments/copa-torneio-facil-de-jiu-jitsu/tabela-copa-torneio-facil-de-jiu-jitsu.jpg";
import qrCodecopaAmizadeDeJiuJitsu2024 from "../../assets/images/tournaments/copa-amizade-de-jiu-jitsu-2024/qr-coce--copa-amizade-de-jiu-jitsu-2024.jpg";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const folderTorneio = require("../../assets/images/tournaments/copa-torneio-facil-de-jiu-jitsu/copa-torneio-facil-de-jiu-jitsu.jpg")
const rotaInscriçãoGi = "/enrollment/copa-torneio-facil-de-jiu-jitsu-manaus-am-gi";
const rotaInscriçãoNoGi = "/enrollment/copa-torneio-facil-de-jiu-jitsu-manaus-am-nogi";
const rotaChecagemGi = "/check/copa-torneio-facil-de-jiu-jitsu-manaus-am-gi/search";
const rotaChecagemNoGi = "/check/copa-torneio-facil-de-jiu-jitsu-manaus-am-nogi/search";
const rotaChavesGI = "http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.DB5B8D07&u=teste"
const rotaChavesNoGI = "/enrollment/copa-fronteira-de-jiu-jitsu-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr";



<TournamentLink
                href="http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.DB5B8D07&u=teste"
                target="_blank"
              >
                Resultados
              </TournamentLink>



const dataEvento = "15 de março de 2025";
const horaEvento = "08:30";
const tituloEvento = "I COPA NORTE FIGHT DE JIU JITSU GI - NOGI";
const localEvento = "Manaus-Am";
const endereçoEvento = "Av. Constantino Nery, s/n - Flores, Manaus - AM, 69040-001";
const dataDeEncerramentoIncricao = "13/MAR/2025";
const horaDeEncerramentoIncricao = "20h:00min";

const dataDeEncerramentoCorrecao = "13/MAR/2025";
const horaDeEncerramentoCorrecao = "20h:00min";

const dataDeEncerramentoInscricaoLote1 = "31/JAN/2025";
const dataDeEncerramentoInscricaoLote2 = "13/FEV/2025";
const dataDeEncerramentoInscricaoLote3 = "18/FEV/2025";

const valorinscricaoCategoriaGi = "R$1,00 (Um real)";
const valorinscricaoCategoriaNoGi = "R$1,00 (Um real)";
const valorinscricaoCategoriaComboGiNoGi = "R$2,00 (Dois reais)";
const valorinscricaoCategoriaLote3 = "R$1,00 (Um real)";

const valorinscricaoAbsolutoLote1 = "R$1,00 (um real)";
const valorinscricaoAbsolutoLote2 = "R$1,00 (um real)";

const dataDeDivulgacaoChaves = "14/MAR/2025";
const horaDeDivulgacaoChaves = "23h:59min";

const instituicaoBancaria = "Inter";
const tipoDeChavePix = "E-MAIL";
const ChavePix = "financeiro@torneiofacil.com";
const BeneficiarioDaChavePix = "Thiago Souza Dellarmelina";

const dataDeDivulgacaoCronograma = "22/FEV/2025";
const horaDeDivulgacaoCronograma = "18/01/2025";

const contatoWhatsApp = "+55 (11) 94456-3515";
const contatoWhatsAppTorneioFacil = "+55 (11) 94456-3515";

const slugGI = "copa-torneio-facil-de-jiu-jitsu-manaus-am-gi";
const slugNOGI = "copa-torneio-facil-de-jiu-jitsu-manaus-am-nogi";

const sponsors = [
  /*   { img: govCascavel },
    { img: escoveSeVilaA },
    { img: agitoTur },
    { img: donaMadameBoutique },
    { img: goldStar },
    { img: prediletaRestaurante },
    { img: govParana }, */
  { img: torneio, url: "#" },
];

const support = [{ img: torneio }];

const CopaTorneioFacilDeJiuJitsu: React.FC = () => {


  useEffect(() => {
    // Adiciona cache buster se não estiver presente
    if (!window.location.search.includes("cacheBuster")) {
      const cacheBuster = `cacheBuster=${new Date().getTime()}`;
      const newUrl = `${window.location.pathname}?${cacheBuster}`;
      window.location.replace(newUrl); // Redireciona para a nova URL
    }
  }, []);



  const { authenticated } = useAuthenticate();
  const navigate = useNavigate();
  const [tournamentGI, setTournamentGI] = useState<any>();
  const [tournamentNOGI, setTournamentNOGI] = useState<any>();

  useEffect(() => {
    api.get(`/tournaments/${slugGI}`).then(({ data }) => {
      setTournamentGI(data);
    });

    api.get(`/tournaments/${slugNOGI}`).then(({ data }) => {
      setTournamentNOGI(data);
    });
  }, []);

  const handleToSubscriptionPage = (url: string) => {
    if (tournamentGI!.canSubscribe || tournamentNOGI!.canSubscribe) {
      navigate(url);
      return;
    }

    const msg = "Período de inscrição encerrado.";

    Swal.fire({
      title: 'Atenção!',
      text: msg,
      icon: 'error',
    });

    toast.error(msg);
  }

  return (
    <>
      <div translate="no">
        <NavBar />
        <Container>
          <TournamentContainer image={folderTorneio}>
            <TournamentContainerBanner>
              <TournamentBanner image={folderTorneio} />
            </TournamentContainerBanner>
          </TournamentContainer>
          <TournamentContent>
            <Content>
              <Title>{tituloEvento}</Title>
              <Location>
                <Span>Data: {dataEvento}</Span>
                <Span>Início: {horaEvento}</Span>
                <Span>Local: {localEvento}</Span>
                <Span>Endereço: {endereçoEvento}</Span>
                <Span>
                  <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                    <FaMapMarkerAlt style={{ marginRight: "8px", color: "red" }} />
                    <a
                      target={"blank"}
                      style={{ color: "#000", textDecoration: "none", fontWeight: "bold" }}
                      href="https://maps.app.goo.gl/Vi4ipU7fCZPDb5wQA"
                    >
                      Ver no Google Maps
                    </a>
                  </div>
                </Span>
              </Location>
              <P></P>
              <br />
            </Content>
          </TournamentContent>
          <TournamentDescription>
            <TournamentActions>
              <Content>
                <h1 style={{ textAlign: "center" }}>PATROCINADORES</h1>
                <SponsorContainer>
                  <SponsorList>
                    {sponsors &&
                      sponsors.map((buttons: any, index: number) => (
                        <SponsorButton
                          key={index} // Adicionei uma chave única para cada botão
                          img={buttons.img}
                          url={buttons.url}
                          hasError={false}
                          error="Este campo é obrigatório"
                          target="_blank" // Adicione este atributo
                        />
                      ))}
                  </SponsorList>
                </SponsorContainer>
                <h1 style={{ textAlign: "center" }}>APOIO</h1>
                <SponsorContainer>
                  <SponsorList>
                    {support &&
                      support.map((buttons: any) => (
                        <SponsorButton
                          img={buttons.img}
                          url={buttons.url}
                          hasError={false}
                          error="Este campo é obrigatório"
                        />
                      ))}
                  </SponsorList>
                </SponsorContainer>
              </Content>

              <Content
                style={{
                  marginTop: "32px",
                }}
              >
                <EditalContainer>
                  <EditalTitle>EDITAL {tituloEvento.toLocaleUpperCase()}</EditalTitle>
                  <EditalContent>
                    (INFORMAÇÕES EM BREVE)
                    <Span>
                      A {tituloEvento} é um evento tradicional  que
                      fomenta e incentiva a prática do jiu jitsu na cidade de Manaus-AM . As lutas
                      serão GI (com kimono) e NOGI (sem kimono), o evento disponibilizará
                      categorias para todas as idades e faixas, conforme as tabelas de peso
                      deste edital. Os competidores terão a oportunidade de testar suas
                      habilidades em um ambiente controlado e com acompanhamento de
                      profissionais qualificados.
                    </Span>
                    <br />
                    <br />
                    <br />
                  </EditalContent>

                  {/* <Collapse title="PROTOCOLO DE SEGURANÇA CONTRA COVID-19"> */}
                  {/* (INFORMAÇÕES EM BREVE) */}
                  {/*  <P>
                    Devido a dinâmica da situação atual, estamos avaliando diariamente as
                    recomendações dos Órgãos de Saúde e este protocolo pode ser atualizado
                    a qualquer momento caso novas determinações sejam publicadas.
                  </P>
                  <P>
                    Caso o evento seja cancelado por força maior, todas as inscrições
                    serão automaticamente reembolsadas.
                  </P>
                  <P>
                    A organização não é responsável pelos demais custos dos participantes,
                    tais como transporte e hospedagem.
                  </P>
                  <P>
                    Diretrizes do ginásio: Para a segurança de todos, por favor verifique
                    sua temperatura antes de se dirigir ao evento.{" "}
                  </P>
                  <P>Caso haja qualquer sintoma, procure um médico.</P>{" "}
                  <P>
                    {" "}
                    Caso você se sinta indisposto ou esteve em contato com um paciente
                    diagnosticado com Covid-19, por favor fique em casa e procure seu
                    médico.
                  </P> */}
                  {/* <P>
                  Todo nosso material será desinfetado antes e durante o evento.
                </P> */}
                  {/* </Collapse> */}
                  <Collapse title="PREMIAÇÕES MEDALHAS ✈️💰🥇">
                    <H3>Academias:</H3>
                    <P>1° lugar: Troféu + R$1500,00</P>
                    <P>2° lugar: Troféu + R$800,00</P>
                    <P>3° lugar: Troféu + R$600,00</P>

                    <br />


                    <H3>Absoluto Masculino GI:</H3>
                    <H5>Master 1, 2, 3, 4 e 5 Preta (Todos Juntos)</H5>
                    <P>1° lugar:  1 Passagem aérea Brasileiro CBJJ + Medalha🥇</P>
                    <P>2° lugar: medalha🥈</P>
                    <P>3° lugar: medalha🥉</P>
                    <br />


                    <H3>Absoluto Feminino GI:</H3>
                    <H5>Adulto Azul e Roxa Juntos</H5>
                    <P>1° lugar: R$500,00 + Medalha🥇</P>
                    <P>2° lugar: medalha🥈</P>
                    <P>3° lugar: medalha🥉</P>
                    <H5>Adulto e Master Preta(Todos Juntos)</H5>
                    <P>1° lugar: R$1000,00 + Medalha🥇</P>
                    <P>2° lugar: medalha🥈</P>
                    <P>3° lugar: medalha🥉</P>

                    <br />

                    <H3>Demais Categorias GI e NOGI</H3>
                    <P>1° lugar: medalha🥇</P>
                    <P>2° lugar: medalha🥈</P>
                    <P>3° lugar: medalha🥉</P>
                    <br />


                    <p> *
                      Estas premiações só irão valer para os Absolutos que tiverem 04
                      (QUATRO) ou mais atletas inscritos.
                    </p>
                    <p>
                      Em caso de apenas 3 (TRÊS) ou menos inscritos no Absoluto, não haverá premiação.
                    </p>

                    <P>
                      Para a realização de lutas na categoria absoluto, é exigido um mínimo
                      de 4 atletas inscritos.
                    </P>
                    <p>
                      Mais informações sobre as inscrições para o absoluto está disponível
                      na seção INSCRIÇÕES, parágrafo Inscrição de Absoluto deste edital.
                    </p>
                    <br />
                  </Collapse>

                  <Collapse title="INSCRIÇÕES">
                    {/* (INFORMAÇÕES EM BREVE) */}
                    <Span>
                      As inscrições para o evento serão realizadas exclusivamente online,
                      por meio do site www.torneiofacil.com.br
                    </Span>
                    <P>
                      Durante o processo de inscrição, o atleta ou seu responsável legal
                      deverá selecionar corretamente a categoria referente idade que o
                      atleta completará no ano de 2025, bem como sua faixa e peso.
                    </P>
                    <P>
                      A idade de competição do atleta é a idade máxima que ele terá em 2025,
                      conforme regra da CBJJ.
                    </P>
                    <br />

                    <H3>Prazo de Inscrição</H3>
                    <P>
                      O prazo final para inscrições e pagamento será até o dia{" "}
                      {dataDeEncerramentoIncricao}, às {horaDeEncerramentoIncricao}.
                    </P>
                    <br />
                    <P>As inscrições que não forem pagas serão canceladas.</P>
                    <br></br>

                    <H3>Correção</H3>

                    <P>
                      A correção da inscrição poderá ser realizada somente após a
                      confirmação do pagamento.
                    </P>
                    <P>
                      Em caso de inscrição equivocada, o atleta ou professor responsável
                      poderá corrigir as informações no site www.torneiofacil.com.br,
                      acessando a opção Meu Perfil em seguida Minhas Inscrições.
                    </P>
                    <P>
                      A correção da inscrição ficará disponível até o encerramento das
                      inscrições {dataDeEncerramentoCorrecao} às{" "}
                      {horaDeEncerramentoCorrecao}.
                    </P>
                    <P>
                      Após prazo para correção das inscrições, não serão aceitas
                      modificações nas inscrições.
                    </P>
                    <P>
                      Os atletas que se inscreverem incorretamente estarão sujeitos a
                      desclassificação <b>sem direito a reembolso.</b>
                    </P>

                    <br />

                    <H3>Prazo de Correção</H3>
                    <P>
                      A correção da inscrição fica disponível após a confirmação do
                      pagamento da mesma.
                    </P>


                    <H3>Chaves</H3>

                    <P>
                      O sorteio e divulgação das chaves de lutas será no dia{" "}
                      {dataDeDivulgacaoChaves} até às {horaDeDivulgacaoChaves}.
                    </P>

                    <br />
                    <H3>Inscrição de Absoluto</H3>
                    <P>
                      Somente atletas inscritos na categoria poderão se inscrever e
                      participar do absoluto.
                    </P>
                    <P>
                      Os atletas só poderão se inscrever no absoluto referente a categoria
                      da sua inscrição no torneio. Exceção apenas para os master que poderá
                      se inscrever como absoluto adulto.
                    </P>
                  </Collapse>
                  <Collapse title="PESSOAS COM DEFICIÊNCIA (PCD)">
                    <H3>
                      As pessoas com deficiência (PCD) devem informar, no momento da
                      inscrição, sua condição pelo WhatsApp. Caso contrario o atleta será
                      desclassificado sem direito a reembolso.
                    </H3>
                    <br />
                  </Collapse>
                  <Collapse title="VALOR DA INSCRIÇÃO">
                    {/* (INFORMAÇÕES EM BREVE) */}

                    <span>
                      <b>LOTE ÚNICO (PREÇO PROMOCIONAL)</b>{" "}
                    </span>
                    <br />
                    <span>R$ 60,00 CATEGORIA GI</span>
                    <br />
                    <span>R$ 60,00 CATEGORIA NOGI</span>
                    <br />
                    <span>R$ 100,00 COMBO CATEGORIA GI+NOGI</span>
                    <br />
                    <span>R$ 40,00 ABSOLUTO</span>
                    <br />
                
                    <b>OBS: O NOGI SERÁ LUTAS CASADAS POR PESSO E FAIXA APENAS 50 LUTAS.</b>
                    <br />

                  </Collapse>

                  <Collapse title="CATEGORIAS COM APENAS UM COMPETIDOR (SEM LUTA)">
                    <Span>
                      Atletas que estiverem sozinhos na categoria terão 02 opções:
                    </Span>
                    <P>
                      Permanecer sozinho na categoria, ser campeão e medalhar sem lutar;
                    </P>
                    <P>Mudar de categoria até a data de encerramento das inscrições;</P>
                    <P>NÃO HAVERÁ LUTAS CASADAS NESSE EVENTO</P>
                  </Collapse>

                  <Collapse title="REEMBOLSO DAS INSCRIÇÕES">
                    <H3>
                      Em caso de Lesão ou doença , o atleta poderá solicitar o reembolso da
                      inscrição, sendo obrigatório a comprovação e apresentação de atestado
                      médico.
                    </H3>
                    <H3>
                      Após o fim do período de inscrição não será possível o reembolso das
                      inscrições pagas.
                    </H3>
                  </Collapse>
                  {/* <Collapse title="PAGAMENTO"> */}
                  {/* (INFORMAÇÕES EM BREVE) */}
                  {/*      <H3>
                    A confirmação de pagamento deve ser feita pelo envio do comprovante via WhatsApp para o contato abaixo.
                  </H3>
                  <H3>
                    Caso o atleta não envie o comprovante de pagamento e o número de sua inscrição até o fim do período de
                    inscrições ({dataDeEncerramentoInscricaoLote1}) , ele será desclassificado sem direito a reembolso.
                  </H3> */}
                  {/* <P>Banco: {instituicaoBancaria}</P>
                  <P>Titular: {BeneficiarioDaChavePix}</P>
                  <P>Tipo de chave PIX: {tipoDeChavePix}</P>
                  <P>Chave PIX: {ChavePix}</P> */}
                  {/* <QRCodeImg src={qrCodecopaAmizadeDeJiuJitsu2024} alt="qrCode" width={300} />
                  <P>
                    <a
                      target={"blank"}
                      style={{ color: "white" }}
                      href="https://whatsa.me/5592981291027/?t=Ol%C3%A1+Thiago_Dellarmelina%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+na+%2ACOPA+ORLEY+LOBATO+DE+JIU+JITSU+2024%2A"
                    >
                      <strong>WhatsApp: +55 (92) 98129-1027 </strong>
                      <FaWhatsapp />
                    </a>
                  </P> */}
                 
                  {/* <QRCodeImg src={qrCodeCopaParana} alt="qrCode" width={300} /> */}
                  {/* </Collapse> */}

                  {/* <Collapse title="CATEGORIAS COM APENAS UM COMPETIDOR (SEM LUTA)"> */}
                  {/* (INFORMAÇÕES EM BREVE) */}
                  {/*  <Span>
                    Atletas que estiverem sozinhos na categoria terão 02 opções:
                  </Span>
                  <P>
                    Permanecer sozinho na categoria, ser campeão e medalhar sem lutar;
                  </P>
                  <P>Mudar de categoria até a data de encerramento das inscrições;</P>
                  <P>NÃO HAVERÁ LUTAS CASADAS NESSE EVENTO</P> */}
                  {/* </Collapse> */}

                  {/* <Collapse title="REEMBOLSO DAS INSCRIÇÕES"> */}
                  {/* (INFORMAÇÕES EM BREVE) */}
                  {/* <H3>
                    Em caso de Lesão ou doença , o atleta poderá solicitar o reembolso da
                    inscrição, sendo obrigatório a comprovação e apresentação de atestado
                    médico.
                  </H3>
                  <H3>
                    Após o fim do período de inscrição não será possível o reembolso das
                    inscrições pagas.
                  </H3> */}
                  {/* </Collapse> */}

                  <Collapse title="REGRAS">
                    <Span>
                      Todas as regras para o evento estão devidamente estipuladas e
                      detalhadas neste edital.
                    </Span>
                    <Span>
                      Em casos de omissão de regras no presente edital, as normas a serem
                      observadas serão aquelas estabelecidas pela Confederação Brasileira de
                      Jiu-Jitsu (CBJJ), em conformidade com as diretrizes da CBJJ/IBJJF.
                    </Span>
                    <Span>
                      A tabela de peso por categoria, que estabelece os limites de peso para
                      cada categoria, encontra-se disponível no site oficial do evento,
                      acessível por meio do botão denominado "Tabela de Peso".
                    </Span>
                    <Span>
                      As lutas serão com GI (com kimonos de cores Oficiais pela cbjj branco,
                      azul e preto ) os kimonos devem estar limpos sem rasgos e sem odor .
                    </Span>
                    <Span>
                      Haverá premiação em dinheiro para para a academia campeã.
                    </Span>
                    {/* <Span>
                    Haverá premiação em dinheiro para o primeiro lugar , somente na
                    categoria absoluto.
                  </Span> */}
                    <Span>
                      O atleta que perdeu a semifinal para o Campeão ficará em terceiro
                      lugar e o atleta que perdeu a semifinal para o Vice-Campeão ficará em
                      quarto lugar.
                    </Span>
                    <Span>
                      Para categoria com 3 lutadores, os atletas farão a melhor de três para
                      definição do campeão, segundo e terceiro lugar conforme regra da CBJJ
                    </Span>
                    <Span>
                      A pesagem será realizada no dia do evento. O competidor deverá
                      apresentar documento oficial com foto no momento da pesagem.
                    </Span>
                    <Span>
                      A pesagem dos atletas serão realizadas antes do inicio da categoria.
                    </Span>
                    <Span>
                      Atletas que ultrapassarem o limite de peso da categoria que estiverem
                      inscritos serão automaticamente desclassificados e não poderão lutar o
                      Absoluto.
                    </Span>
                    <Span>Será realizada apenas uma pesagem oficial por atleta.</Span>
                    {/* <Span>
                    Teremos uma balança para pesagem livre (não oficial) para os atletas.
                  </Span> */}
                    {/* <Span>Teremos uma balança para pesagem oficial dos atletas.</Span>*/}
                    <Span>
                      Atletas que lutaram em outros eventos com faixa superior não poderão
                      lutar com a faixa abaixo. Se descoberto, o competidor será eliminado
                      do torneio sem direito a reembolso.
                    </Span>
                    <br />
                    <H3>Pontuação por equipe</H3>
                    <H5>Categoria e absoluto</H5>
                    <P>1° lugar: 9 pontos</P>
                    <P>2° lugar: 3 pontos</P>
                    <P>3° lugar: 1 ponto</P>

                    <Span>
                      Campão direto e chaves com todos os atletas da mesma academia
                      vão pontuar normalmete.
                    </Span>

                    <P>
                      A contagem dos pontos será realizada de forma geral contabilizando os
                      pontos de todas as faixas.
                    </P>
                    <P>Campeão direto contam pontos, chaves com apenas um inscrito.</P>
                    <P>
                      Campeão por WO, devido a desistencia do oponente, contará pontos mesmo
                      que na chave tenha somente um inscrito.
                    </P>
                    <P>
                      Categorias com apenas dois atletas da mesma academia  contam pontos
                      para a disputa por academias.
                    </P>
                    <P>
                      O que não estiver neste edital, será acatado o que estiver no livro de
                      regras da CBJJ.
                    </P>
                    <br />
                    <br />
                    {/* <P>
                    <a
                      target={"blank"}
                      href="https://cbjj.com.br/books-videos"
                      style={{ color: "white" }}
                    >
                      <strong>LIVRO DE REGRAS DA CBJJ/IBJJF</strong>
                    </a>
                  </P> */}
                  </Collapse>
                  <Collapse
                    title={
                      "CRONOGRAMA "
                    }
                  >
                    <Container>
                      <Span>Data: 15 de março de 2025</Span>
                      <Span>Início: 08:30h da manhã</Span>

                      <H3>Lutas GI</H3>
                      <H5>08:30 - Abertura do evento </H5>
                      <H5>09:00 - Feminino Todas as categorias - todas as faixas</H5>
                      <H5>11:15 - Categorias Pré-Mirim e Mirim A - Todas as Faixas masculino</H5>
                      <H5>11:45 - Categoria Mirim B - Todas as Faixas masculino</H5>
                      <H5>12:20 - Infantil A - Todas as Faixas masculino </H5>
                      <H5>13:00 - Infantil B - Todas as faixas masculino</H5>
                      <H5>13:50 - Infanto-juvenil Todas as faixas Masculino</H5>
                      <H5>14:30 - Juvenil - Todas as faixas masculino</H5>
                      <H5>15:30 - Absoluto - Todas as faixas feminino</H5>
                      <H5>16:00 - Faixa Branca Adulto e Masters masculino</H5>
                      <H5>16:30 - Todas as Faixa Adulto e Master Masculino</H5>
                      <H5>16:30 - Absoluto GI Masculino</H5>

                      <Span><p>LUTAS CASADAS NOGI</p></Span>
                      <H5>11:00 - Ínicio dos lutas casadas Masculino e Feminino </H5>
                      <br />
                      <Span>
                        O cronograma pode ser adiantado em até 1 hora, e atrasar conforme a
                        necessidade do evento.
                      </Span>
                      <Span>
                        O atleta deverá estar presente no ginásio pelo menos 1 hora antes da
                        sua luta.
                      </Span>
                      <Span>
                        O atleta que não estiver presente no momento da chamada para a
                        pesagem ou luta será desclassificado por W.O.
                      </Span>
                      <Span>
                        A pesagem será feita antes da 1º luta do atleta, em caso do atleta
                        não bater o peso, este será imediatamente desclassificado, será
                        verificado também as condições do kimono do atleta antes da luta,
                        podendo ocorrer desclassificação.
                      </Span>

                      <Span>
                        Para o que não estiver presente neste edital, seguiremos normas e regras da CBJJ.
                      </Span>

                      <br />
                      <b>
                        <Span>Obs: </Span>
                      </b>
                      <H3> Desejamos a todos um excelente campeonato!!!</H3>
                      <H3>Ossssssssss</H3>
                    </Container>
                  </Collapse>
                  <Collapse title="ENTRADA">
                    {/* (INFORMAÇÕES EM BREVE) */}
                    <P>Entrada liberada ao público.</P>
                    <br />
                    {/* <P>
                  Será cobrado 1kg de alimento não perecível na entrada para atletas e
                  público.
                </P> */}
                    {/* <br />
                <P>Crianças até 5 anos ficam isentas de qualquer tipo de pagamento.</P>
                <br /> */}
                  </Collapse>
                  <Collapse title="CONTATO">
                    {/* y */}
                    <P>
                      Dúvidas sobre cadastro, inscrição, pagamento, correção, checagem ou
                      sugestão de melhorias entre em contato com a equipe do Torneio Fácil
                      por telefone ou WhatsApp.
                    </P>
                    <P>Melhor horário para atendimento das 18:00h as 23:59h.</P>
                    <a
                      target={"blank"}
                      href="https://whatsa.me/5511944563515/?t=Olá%20Equipe%20Torneio%20Fácil!%0A%0APreciso%20de%20ajuda%20com%20minha%20inscri%C3%A7%C3%A3o%20na%20*COPA%20NORTE%20FIGHT%20DE%20JIU%20JITSU*"
                    >
                      <strong>Telefone/WhatsApp: {contatoWhatsAppTorneioFacil} </strong>
                      <FaWhatsapp />
                    </a>

                    <br />
                  </Collapse>
                </EditalContainer>
              </Content>
              <br />
              <Content>
                {authenticated ? (
                  <>
                    {/* <TournamentEnrollmentLinkEndpage to={rotaInscriçãoGi}>
                    Inscrição GI
                  </TournamentEnrollmentLinkEndpage>

                  <TournamentEnrollmentLinkEndpage to={rotaInscriçãoNoGi}>
                    Inscrição NOGI
                  </TournamentEnrollmentLinkEndpage> */}

                    <TournamentEnrollmentLinkEndpage to={""} onClick={() => handleToSubscriptionPage(rotaInscriçãoGi)}>
                      Inscrição
                    </TournamentEnrollmentLinkEndpage>

                    <TournamentEnrollmentLinkEndpage to="/profile/subscription">
                      Correção da Inscrição
                    </TournamentEnrollmentLinkEndpage>
                  </>
                ) : (
                  <>
                    <TournamentEnrollmentLinkEndpage to="" onClick={() => handleToSubscriptionPage("/login")}>
                      Inscrição
                    </TournamentEnrollmentLinkEndpage>

                    <TournamentEnrollmentLinkEndpage to="/login">
                      Correção da Inscrição
                    </TournamentEnrollmentLinkEndpage>
                  </>
                )}

                <TournamentLink
                  // target="_blank"
                  href={rotaChecagemGi}
                >
                  Checagem GI
                </TournamentLink>

                {/*  <TournamentLink
                // target="_blank"
                href={rotaChecagemNoGi}
              >
                Checagem NOGI
              </TournamentLink> */}

                <TournamentLink
                  // target="_blank"
                  href={rotaChavesGI}
                >
                  Chaves
                </TournamentLink>

                <TournamentLink target="blank" href={tabelaPeso}>
                  Tabela de pesos
                </TournamentLink>
                {/* <TournamentLink href="#">Resultados (EM BREVE)</TournamentLink> */}
              </Content>
            </TournamentActions>
          </TournamentDescription>
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default CopaTorneioFacilDeJiuJitsu;
